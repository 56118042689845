import { styled } from '@mui/system'
import { Button as Btn } from '@mui/material'
import { Link as Lnk } from 'react-router-dom'

export const Button = styled(Btn)((props) => `
  box-sizing: border-box;
  border-radius: ${props?.theme?.components?.MuiButton?.styleOverrides?.shape?.borderRadius || 4}px;
  ${(props?.color === 'secondary' && props?.variant === 'outlined') && `color: ${props?.theme?.palette?.secondary?.color}`};
  ${props?.minwidth === 'true' && 'min-width: 140px'};
  ${props?.fullwidth === 'true' && 'min-width: 100%'};
  @media (max-width: ${props?.theme?.breakpoints?.values?.sm || 600}px){
    ${props?.minwidth === 'true' && 'min-width: auto'};
  }
`)

export const Link = styled(Lnk)(`
  text-decoration: none;
  & button {
    text-decoration: none;
  }
`)
