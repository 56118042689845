import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Grid, Typography } from '@mui/material';
import PostLogin from '../../layout/post-login';
import AgentService from '../../services/agent.service';
//graph
import '@react-sigma/core/lib/react-sigma.min.css';
import Graph from 'graphology';
import {
	ControlsContainer,
	FullScreenControl,
	SearchControl,
	SigmaContainer,
	ZoomControl,
	useLoadGraph,
	useRegisterEvents,
	useSigma,
} from '@react-sigma/core';
import { LayoutForceAtlas2Control } from '@react-sigma/layout-forceatlas2';
import { useLayoutRandom } from '@react-sigma/layout-random';
import Loader from '../../components/Loader';
const LoadGraphWithByProp = () => {
	const { enqueueSnackbar } = useSnackbar();
	const [agentDataPage, setAgentDataPage] = useState(0);
	const [agentDataLimit, setAgentDataLimit] = useState(1000);
	const [filterDate, setFilterDate] = useState(null);
	const [agentData, setAgentData] = useState();
	const [triggerLoad, setTriggerLoad] = useState(false);
	const [gridLoader, setGridLoader] = useState(false);

	//graph setup

	const loadAgentData = () => {
		setGridLoader(true);
		AgentService.getAgentList(agentDataPage, agentDataLimit, filterDate)
			.then((response) => {
				// setLoading(false)
				setAgentData(response?.data?.data);
				setGridLoader(false);
			})
			.catch((err) => {
				// setLoading(false)
				if (err?.response?.data) {
					enqueueSnackbar(err.response.data.message, { variant: 'error' });
				} else {
					enqueueSnackbar(err.message, { variant: 'error' });
				}
			});
	};

	useEffect(() => {
		loadAgentData();
	}, [agentDataPage, agentDataLimit, triggerLoad]);

	function randomColor() {
		var letters = '0123456789ABCDEF';
		var color = '#';
		for (var i = 0; i < 6; i++) {
			color += letters[Math.floor(Math.random() * 16)];
		}
		return color;
	}

	const AgentGraph = () => {
		const sigma = useSigma();
		const { assign } = useLayoutRandom();
		const loadGraph = useLoadGraph();
		useEffect(() => {
			// Create the graph
			const graph = new Graph();
			agentData.agents.map((agent, agentKey) => {
				graph.addNode(agent.id, {
					label: 'Name: ' + agent.name + ' Scheme:' + agent.multilineScheme,
					size: 10,
					color: randomColor(),
					x: Math.random(),
					y: Math.random(),
				});
			});
			// add edges with reference to agent id
			agentData.agents.map((agent, agentKey) => {
				agentData.agents.map((agent2, agentKey2) => {
					if (agent2.referred_by && agent.id === agent2.referred_by) {
						graph.addEdgeWithKey(agentKey + '' + agentKey2, agent.id, agent2.id, {
							size: 3,
							color: '#ccc',
							type: 'arrow',
						});
					}
				});
			});

			loadGraph(graph);
			assign();
		}, [assign, loadGraph, randomColor]);

		return null;
	};

	const GraphEvents = () => {
		const registerEvents = useRegisterEvents();
		const sigma = useSigma();
		// hide labels
		const [draggedNode, setDraggedNode] = useState(null);

		useEffect(() => {
			// Register the events
			registerEvents({
				downNode: (e) => {
					setDraggedNode(e.node);
					sigma.getGraph().setNodeAttribute(e.node, 'highlighted', true);
				},
				mouseup: (e) => {
					if (draggedNode) {
						setDraggedNode(null);
						sigma.getGraph().removeNodeAttribute(draggedNode, 'highlighted');
					}
				},
				// highlight edges on entering in node
				enterNode: (e) => {
					sigma
						.getGraph()
						.neighbors(e.node)
						.forEach((node) => {
							// get all edges of this node
							sigma
								.getGraph()
								.edges(node)
								.forEach((edge) => {
									// highlight edges of this node
									sigma.getGraph().setEdgeAttribute(edge, 'color', 'red');
								});
						});
				},
				// unhighlight edges on leaving node
				leaveNode: (e) => {
					//console.log(e);
					sigma
						.getGraph()
						.neighbors(e.node)
						.forEach((node) => {
							// get all edges of this node
							sigma
								.getGraph()
								.edges(node)
								.forEach((edge) => {
									// highlight edges of this node
									sigma.getGraph().setEdgeAttribute(edge, 'color', '#ccc');
								});
						});
				},
				mousedown: (e) => {
					// Disable the autoscale at the first down interaction
					if (!sigma.getCustomBBox()) sigma.setCustomBBox(sigma.getBBox());
				},
				mousemove: (e) => {
					if (draggedNode) {
						// Get new position of node
						const pos = sigma.viewportToGraph(e);
						sigma.getGraph().setNodeAttribute(draggedNode, 'x', pos.x);
						sigma.getGraph().setNodeAttribute(draggedNode, 'y', pos.y);

						// Prevent sigma to move camera:
						e.preventSigmaDefault();
						e.original.preventDefault();
						e.original.stopPropagation();
					}
				},
				touchup: (e) => {
					if (draggedNode) {
						setDraggedNode(null);
						sigma.getGraph().removeNodeAttribute(draggedNode, 'highlighted');
					}
				},
				touchdown: (e) => {
					// Disable the autoscale at the first down interaction
					if (!sigma.getCustomBBox()) sigma.setCustomBBox(sigma.getBBox());
				},
				touchmove: (e) => {
					if (draggedNode) {
						// Get new position of node
						const pos = sigma.viewportToGraph(e);
						sigma.getGraph().setNodeAttribute(draggedNode, 'x', pos.x);
						sigma.getGraph().setNodeAttribute(draggedNode, 'y', pos.y);

						// Prevent sigma to move camera:
						e.preventSigmaDefault();
						e.original.preventDefault();
						e.original.stopPropagation();
					}
				},
				// highlight edges on entering in edge
				enterEdge: (e) => {
					sigma.getGraph().setEdgeAttribute(e.edge, 'color', 'red');
				},
				// unhighlight edges on leaving edge
				leaveEdge: (e) => {
					sigma.getGraph().setEdgeAttribute(e.edge, 'color', '#ccc');
				},
			});
		}, [registerEvents, sigma, draggedNode]);

		return null;
	};
	return (
		<PostLogin>
			<Grid container direction="row" justifyContent="space-between" alignItems="center">
				<Grid item>
					<Typography variant="h5" gutterBottom component="h5">
						Agents Tree{' '}
					</Typography>
					{gridLoader && <Loader showLoader />}
				</Grid>

				{agentData && (
					// <SigmaContainer graph={MultiDirectedGraph} style={{ height: 750 }}>
					// 	<AgentGraph />
					// 	<GraphEvents />
					// </SigmaContainer>
					<SigmaContainer style={{ height: '700px' }}>
						<AgentGraph order={100} probability={0.1} />
						<GraphEvents />
						<ControlsContainer position={'bottom-right'}>
							<ZoomControl />
							<FullScreenControl />
							<LayoutForceAtlas2Control settings={{ settings: { slowDown: 10 } }} />
						</ControlsContainer>
						<ControlsContainer position={'top-right'}>
							<SearchControl style={{ width: '200px' }} />
						</ControlsContainer>
					</SigmaContainer>
				)}
			</Grid>
		</PostLogin>
	);
};

export default LoadGraphWithByProp;
