import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';

import Login from '../pages/login';
import Dashboard from '../pages/dashboard';
import Profile from '../pages/profile';
import Agents from '../pages/agents';
import AgentsTree from '../pages/agents-tree';
import Payments from '../pages/payments';
import Policy from '../pages/policy';
import Claim from '../pages/claims';
import ProductList from '../pages/products/list';
import ProductAdd from '../pages/products/add';
import Settings from '../pages/settings';

import BoardUser from '../component-old/BoardUser';
import BoardModerator from '../component-old/BoardModerator';
import BoardAdmin from '../component-old/BoardAdmin';

import AuthService from '../services/auth.service';
import EventBus from '../common/EventBus';
import CompaniesList from '../pages/companies/list';
import CompanyAdd from '../pages/companies/add';
import InsurancePlanAdd from '../pages/insurance-plans/add';
import InsurancePlanList from '../pages/insurance-plans/list';
import QuotationComponent from '../pages/quotations';
import QuotationWaitingApprovalComponent from '../pages/quotations-waiting-approval';
import CarrierUsersComponent from '../pages/carrier-user/users'
import BannerAdd from '../pages/bannerImages/add';
import BannerImagesList from '../pages/bannerImages/list';
import VehicleUpload from '../pages/upload-vehicle-list/components/excelUpload';

const BackOfficeRouter = (props) => {
	useEffect(() => {
		const user = AuthService.getCurrentUser();

		if (AuthService.getCurrentUser() === null) {
			console.log('Please login');
		}
		if (user) {
			// setCurrentUser(user)
			// setShowModeratorBoard(user.roles.includes('ROLE_MODERATOR'))
			// setShowAdminBoard(user.roles.includes('ROLE_ADMIN'))
		}
		const logOut = () => {
			AuthService.logout();
			// setShowModeratorBoard(false)
			// setShowAdminBoard(false)
			// setCurrentUser(undefined)
		};

		EventBus.on('logout', () => {
			logOut();
		});

		return () => {
			EventBus.remove('logout');
		};
	}, []);
	return (
		<Switch>
			{/* <Route exact path="/dashboard" component={Dashboard} /> */}
			<Route exact path={['/', '/login']} component={Login} />
			<Route exact path="/profile" component={Profile} />
			<Route exact path="/agents" component={Agents} />
			<Route exact path="/agent-tree" component={AgentsTree} />
			<Route exact path="/policy" component={Policy} />
			<Route exact path="/claim" component={Claim} />
			<Route exact path="/payments" component={Payments} />
			<Route exact path="/products" component={ProductList} />
			<Route exact path="/products/add" component={ProductAdd} />
			<Route exact path="/products/edit" component={ProductAdd} />
			{/* <Route exact path='/products/**' component={ProductList} /> */}
			<Route exact path="/companies" component={CompaniesList} />
			<Route exact path="/companies/add" component={CompanyAdd} />
			<Route exact path="/companies/edit" component={CompanyAdd} />
			<Route exact path="/insurance-plan" component={InsurancePlanList} />
			<Route exact path="/insurance-plan/add" component={InsurancePlanAdd} />
			<Route exact path="/quotations" component={QuotationComponent} />
			<Route exact path="/waiting-approvals" component={QuotationWaitingApprovalComponent} />
			<Route path="/user" component={BoardUser} />
			<Route path="/settings" component={Settings} />
			{/* <Route path='/account' component={Account} /> */}
			<Route path="/mod" component={BoardModerator} />
			<Route path="/admin" component={BoardAdmin} />
			<Route path="/carrier-users" component={CarrierUsersComponent} />
			<Route path="/banner-images" component={BannerImagesList} />
			<Route path="/banner-image/add" component={BannerAdd} />
			<Route path="/banner-image/edit" component={BannerAdd} />
			<Route path="/vehicle-upload" component={VehicleUpload} />
			<Route path="**" component={Login} />
		</Switch>
	);
};

export default BackOfficeRouter;
