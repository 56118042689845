import axios from 'axios';
import authHeader from './auth-header';
import authService from './auth.service';

const API_URL = `${process.env.REACT_APP_API_URL}of-policy/`;
const POLICY_URL = `${process.env.REACT_APP_POLICY_API_URL}/policies`;

const getPolicyList = (page = 0, limit = 10, data) => {
	const param = data?.search ? `&search=${data?.search}` : '';
	// http://34.87.73.102/backoffice/v1/of-policy/fetchallPolicies?limit=1&page=0 (METHOD=GET)
	const url = `${POLICY_URL}/policies/AllPolicies?limit=${limit}&page=${page}${param}`;
	return axios.get(url, { headers: authHeader() });
};

const getSearchPolicy = (id) => {
	// https://dev.bangjamin.com/backoffice/v1/of-policy/getpolicyNumber/1650382686538
	const url = `${API_URL}getpolicyNumber/${id}`;
	return axios.get(url, { headers: authHeader() });
};

const declinePolicy = (id, data) => {
	// https://dev.bangjamin.com/policy/v1/policies/1650382686538
	const user = authService.getCurrentUser();
	data.updatedBy = `${user?.name} - Backoffice Admin`;
	data.updatedById = user?.id;
	const url = `${POLICY_URL}/${id}`;
	return axios.put(url, data, { headers: authHeader() });
};

const policyDetail = (id) => {
	const url = `${POLICY_URL}/${id}`;
	return axios.get(url, { headers: authHeader() });
};

const uploadSoftCopy = (id, data) => {
	const url = `${POLICY_URL}/softcopy/${id}`;
	return axios.post(url, data, { headers: authHeader() });
};

const bypassCarrier = (quotationId) => {
	const url = `${POLICY_URL}/${quotationId}/bypass-carrier`;
	return axios.post(url, { headers: authHeader() });
};

export default {
	getPolicyList,
	getSearchPolicy,
	declinePolicy,
	policyDetail,
	uploadSoftCopy,
	bypassCarrier,
};
