import React, { useState } from 'react';
import { DashboardNavbar } from './components/dashboard-navbar/dashboard-navbar';
import Lhs from './components/lhs/';

import { PostLoginOuterWrapper, LhsWrapper, RhsWrapper, ContentWrapper } from './style';

const PostLogin = (props) => {
	const { children } = props;
	const [isSidebarOpen, setSidebarOpen] = useState(true);

	return (
		<PostLoginOuterWrapper>
			<LhsWrapper>{<Lhs onClose={() => setSidebarOpen(false)} open={isSidebarOpen} />}</LhsWrapper>
			<RhsWrapper>
				<DashboardNavbar />
				<ContentWrapper>{children}</ContentWrapper>
			</RhsWrapper>
		</PostLoginOuterWrapper>
	);
};

export default PostLogin;
