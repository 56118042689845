import React from 'react'
import PropTypes from 'prop-types'
import { Logo } from '../../assets/images/logo'
import {
  PreLoginOuterWrapper,
  LhsWrapper,
  RhsWrapper,
  PaperWrapper
} from './style'

const PreLogin = ({ children }) => {
  return (
    <PreLoginOuterWrapper>
      <LhsWrapper>
        <Logo />
      </LhsWrapper>
      <RhsWrapper>
        <PaperWrapper>
          {children}
        </PaperWrapper>
      </RhsWrapper>
    </PreLoginOuterWrapper>
  )
}

PreLogin.propTypes = {
  children: PropTypes.node
}

PreLogin.displayName = 'PreLogin'

export default PreLogin
