import * as React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { Typography } from '@mui/material'
import { Wrapper } from './style'

const Loader = (props) => {
  const { showLoader = false, message, outlinedLoader } = props

  return (
    <>
      {showLoader &&
        <>
          {!outlinedLoader
            ? <Wrapper><CircularProgress size={32} thickness={3} /></Wrapper>
            : <CircularProgress size={32} thickness={3} />}
        </>}
      {message && <Typography variant='h6' component='h6'>{message?.message || message || 'No data found!'}</Typography>}
    </>
  )
}
export default Loader
