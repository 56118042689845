import React from 'react'
import { Button, Link } from './style'

const MuiButton = props => {
  let toUrl
  let object = {}

  const _delete = () => {
    const keys = Object.keys(props)
    keys.map(k => {
      if (k !== 'to') object[k] = props[k]
      return k
    })
  }

  if (props?.to) {
    toUrl = props.to; _delete()
  } else {
    object = props
  }

  return (
    <>
      {toUrl ? <Link to={toUrl}><Button {...object} /></Link> : <Button {...object} />}
    </>
  )
}
export default MuiButton
