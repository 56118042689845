import { styled } from '@mui/system'
import { Button } from '@mui/material'

export const LoginButton = styled(Button)(({ theme }) => `
    margin: ${theme.overrides.MuiButton.sizeLarge.margin};
`)

export const StyledSecondaryLink = styled(Button)(`
  text-decoration: none;
  :hover{
    text-decoration: none;
  }
`)
