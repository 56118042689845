import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_INSURANCE_PLAN_API_URL;
const PRODUCT_API_URL = process.env.REACT_APP_PRODUCTS_API_URL;

const getList = (page = 0, limit = 10) => {
	page += 1;
	const url = `${API_URL}?page=${page}&limit=${limit}`;
	return axios.get(url, { headers: authHeader() });
};

const create = (backendData, xsrfToken) => {
	const url = `${API_URL}`;
	return axios.post(url, { ...backendData }, { headers: { ...authHeader(), 'x-xsrf-token': xsrfToken } });
};

const deletePlan = (id, xsrfToken) => {
	const url = `${API_URL}/${id}`;
	return axios.delete(url, { headers: { ...authHeader(), 'x-xsrf-token': xsrfToken } });
};

const statusToggle = (id, status, xsrfToken) => {
	const url = `${API_URL}/${id}/changeStatus`;
	return axios.put(url, { status }, { headers: { ...authHeader(), 'x-xsrf-token': xsrfToken } });
};

const getCompaniesList = () => {
	const url = `${PRODUCT_API_URL}fetchCompanies`;
	return axios.get(url, { headers: authHeader() });
};

export default {
	getList,
	deletePlan,
	create,
	statusToggle,
	getCompaniesList,
};
