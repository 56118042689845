import React from 'react';
import {
	Dialog,
	DialogContent,
	IconButton,
	Typography,
	Grid,
	List,
	ListItem,
	ListItemText,
	Box,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { toLocaleString, policyStatusFormat, policyActiveStatusFormat } from '../../../util';
import moment from 'moment';
import pdfUrl from '../../../assets/images/pdf-placeholder.png'
import imgUrl from '../../../assets/images/image-placeholder.jpg'

const DialogTitle = (props) => {
	const { children, onClose } = props;
	return (
		<Grid container direction="row" justifyContent="space-between" alignItems="center" p={3} pb={0}>
			<Typography variant="h5">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" onClick={onClose}>
					<Close />
				</IconButton>
			) : null}
		</Grid>
	);
};

const PolicyView = (props) => {
	const { onClose, open, data, uploadSoftCopy } = props;
	const quotationData = data?.quotation;
	const myRef = React.useRef(null)

	// const commissionData = data?.quotation?.commissionCalculation ? JSON.parse(data?.quotation?.commissionCalculation) : {};
	// const premiumData = data?.quotation?.premiumCalculation ? JSON.parse(data?.quotation?.premiumCalculation) : {};
	const insuranceProductData = data?.quotation?.insuranceProduct || {};
	const insuranceCompanyData = data?.quotation?.company || {};

	const listingFunc = (title, value) => {
		return value ? (
			<ListItem sx={{ width: '25%' }}>
				{' '}
				<ListItemText primary={title} secondary={value || ''} />{' '}
			</ListItem>
		) : (
			''
		);
	};

	const handleUploadClick = ()=>{
		myRef.current.click();
	}

	const imageErrorHandle = (event) => {
		let img = event.target.src
		if(img.includes('.pdf')){
			event.target.src = pdfUrl
		} else if (img.includes('.png') || img.includes('.jpeg') || img.includes('.jpg')){
			event.target.src = imgUrl
		}else if (img.includes('.exe')){

		}

	}

	const uploadSoftCopyClick = event => {
		const fileUploaded = event.target.files[0];
		const formData = new FormData()
		formData.append('policy', fileUploaded)
		console.log(formData)
		uploadSoftCopy(data?.id, formData)
	  };

	const listingFuncUrl = (title, url, isSoftCopy) => {
		return url ? (
			<ListItem sx={{ width: '25%' }}>
				{' '}
				<ListItemText
					primary={title}
					secondary={<>

					{title=="Policy" && !isSoftCopy && (<>
					<input ref={myRef} type="file" id="imgupload" style={{display:'none'}} accept='.pdf' onChange={uploadSoftCopyClick}/>
					<a onClick={handleUploadClick} href='#'>
						Upload
					</a>
					<span style={{margin:'5px'}}></span>
					</>)
					}
					{' '}
						<a target="_blank" href={url}>
							Download
						</a>
						
						
					</>
					} 
				/>{' '}
				{/* {title == 'Policy' && (<ListItemText
					primary={title}
					secondary={
						
					}
				/>)} */}
			</ListItem>
		) : (
			''
		);
	};

	return (
		<Dialog onClose={onClose} aria-labelledby="view-policy" open={open} maxWidth="lg" fullWidth>
			<DialogTitle id="view-policy" onClose={onClose}>
				View Policy
				{/* show policy status in right side */}
				<div style={{ float: 'right' }}>{policyActiveStatusFormat(data?.isActive, data?.declineReason)}</div>
			</DialogTitle>
			<DialogContent>
				<Box>
					<Grid container spacing={2} sx={{ pb: 4 }}>
						<Grid item xs={12}>
							<Typography sx={{ mt: 0, mx: 2, borderBottom: '1px #d3d3d3 solid' }} variant="h6" component="span">
								Policy details
							</Typography>
							<List sx={{ display: 'flex', flexWrap: 'wrap' }}>
								{listingFunc('Policy Number', data?.policyIdNumber || data?.policyNumber)}
								{listingFunc('Agent Name', data?.agentDetails?.name)}
								{listingFunc('PIC Marketing', data?.picManager?.name)}
								{listingFunc('Payment Method', data?.paymentMethod)}
								{listingFunc('Bank Name', data?.bankName)}
								{listingFunc('Settlement Status', policyStatusFormat(data?.settlementStatus))}
								{listingFunc('Submission Date & Time', moment(data?.createdAt).format('yyyy-MM-DD, hh:mm A'))}
							</List>
						</Grid>

						<Grid item xs={12}>
							<Typography sx={{ mt: 0, mx: 2, borderBottom: '1px #d3d3d3 solid' }} variant="h6" component="span">
								General details
							</Typography>
							<List sx={{ display: 'flex', flexWrap: 'wrap' }}>
								{listingFunc('Customer Name', quotationData?.customerName)}
								{listingFunc('Mobile Number', quotationData?.mobileNumber)}
								{listingFunc('Email', quotationData?.email)}
								{listingFunc('Policy Amount', toLocaleString(quotationData?.policyAmount))}
								{listingFunc('Tax Amount', toLocaleString(quotationData?.taxAmount))}
								{listingFunc('Tax Percentage', `${quotationData?.taxPercentage || 0}%`)}
								{listingFunc('Total Sum Assured Value', quotationData?.totalSumAssuredValue)}
								{listingFunc('Policy Status', policyStatusFormat(quotationData?.policyStatus))}
								{listingFunc('Admin Fee', toLocaleString(quotationData?.administrationCost))}
								{listingFunc('Stamp Duty', toLocaleString(quotationData?.stampDuty))}
							</List>
						</Grid>

						{/* {insuranceProductData && (
							<Grid item xs={12}>
								<Typography sx={{ mt: 0, mx: 2, borderBottom: '1px #d3d3d3 solid' }} variant="h6" component="span">
									Insurance Company Details
								</Typography>
								<List sx={{ display: 'flex', flexWrap: 'wrap' }}>
									{listingFunc('Display Name', insuranceProductData?.displayName)}
									{listingFunc('Code', insuranceProductData?.code)}
									{listingFunc('Rating', insuranceProductData?.rating)}
									{listingFunc('Company Name', insuranceProductData?.company?.companyName)}
								</List>
							</Grid>
						)} */}

						{insuranceCompanyData && (
							<Grid item xs={12}>
								<Typography sx={{ mt: 0, mx: 2, borderBottom: '1px #d3d3d3 solid' }} variant="h6" component="span">
									Insurance Company Details
								</Typography>
								<List sx={{ display: 'flex', flexWrap: 'wrap' }}>
									{listingFunc('Display Name', insuranceCompanyData?.companyName)}
									{listingFunc('Code', insuranceCompanyData?.code)}
									{listingFunc('Rating', insuranceCompanyData?.rating)}
									{listingFunc('Company Name', insuranceCompanyData?.companyNameOnPolicy?.conventional)}
								</List>
							</Grid>
						)}



						{/* {quotationData?.carDetail && (
							<Grid item xs={12}>
								<Typography sx={{ mt: 0, mx: 2, borderBottom: '1px #d3d3d3 solid' }} variant="h6" component="span">
									Car details
								</Typography>
								<List sx={{ display: 'flex', flexWrap: 'wrap' }}>
									{listingFunc('Chassis Number', quotationData?.carDetail?.chassisNumber)}
									{listingFunc('Color', quotationData?.carDetail?.color)}
									{listingFunc('Machine Number', quotationData?.carDetail?.machineNumber)}
								</List>
							</Grid>
						)} */}

						{quotationData?.carDetail && (
							<Grid item xs={12}>
								<Typography sx={{ mt: 0, mx: 2, borderBottom: '1px #d3d3d3 solid' }} variant="h6" component="span">
									Vehicle details
								</Typography>
								<List sx={{ display: 'flex', flexWrap: 'wrap' }}>
									{quotationData?.carDetail && (
										<>
											{listingFunc('Chassis Number', quotationData?.carDetail?.chassisNumber)}
											{listingFunc('Color', quotationData?.carDetail?.color)}
											{listingFunc('Machine Number', quotationData?.carDetail?.engineNumber)}
										</>
									)}
									{quotationData?.plateCode && quotationData?.plateNumber && (
										<>{listingFunc('Plate Number', quotationData?.plateCode + ' ' + quotationData?.plateNumber)}</>
									)}
									{/* {listingFunc('Vehicle Brand Id', quotationData?.vehicleBrandId)} */}
									{listingFunc('Vehicle Condition', quotationData?.vehicleCondition)}
									{listingFunc(
										'Vehicle Coverage',
										typeof quotationData?.vehicleCoverage == 'object' && quotationData?.vehicleCoverage.length
											? quotationData?.vehicleCoverage.join(', ')
											: quotationData?.vehicleCoverage
									)}
									{/* {listingFunc('Vehicle Id', quotationData?.vehicleId)} */}
									{listingFunc('Vehicle Manufacter Year', quotationData?.vehicleManufacterYear)}
									{listingFunc('Vehicle Usage', quotationData?.vehicleUsage)}
									{listingFunc('Vehicle Name', quotationData?.vehicleName)}
									{listingFunc('Vehicle Category', quotationData?.vehicleCategory)}
									{listingFunc('Vehicle Price', toLocaleString(quotationData?.vehiclePrice))}
								</List>
							</Grid>
						)}
						{quotationData?.identityDocument && (
								<Grid item xs={12} sx={{ mx: 2 }}>
									<Typography sx={{ mt: 0, mx: 2, borderBottom: '1px #d3d3d3 solid' }} variant="h6" component="span">
								Personal Documents
								</Typography>
									<Table sx={{ minWidth: 650 }} aria-label="simple table">
										<TableHead>
											<TableRow>
												<TableCell>Name</TableCell>
												<TableCell>File</TableCell>
												<TableCell>Action</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
										

											<TableRow key={4} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
												<TableCell sx={{ py: 1 }}>{`Identity Document`}</TableCell>
												<TableCell sx={{ py: 1 }}>
													<img src={quotationData?.identityDocument } onError={imageErrorHandle} width="80px" />
												</TableCell>
												<TableCell sx={{ py: 1 }}>
													<a target="_blank" href={quotationData?.identityDocument}>
														Download
													</a>
												</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</Grid>
							)}

						<Grid item xs={12}>
							<Typography sx={{ mt: 0, mx: 2, borderBottom: '1px #d3d3d3 solid' }} variant="h6" component="span">
								Documents
							</Typography>
							<List sx={{ display: 'flex', flexWrap: 'wrap' }}>
								{listingFuncUrl('Invoice', data?.invoicePdfLocation, false)}
								{listingFuncUrl('Policy', data?.pdfLocation,!( !data?.isSoftCopy && (data?.isActive && !data?.declineReason) ))}
								{listingFuncUrl('Commission', data?.commissionPdfLocation, false )}
							</List>
						</Grid>

						{quotationData?.customRiders && (
							<Grid item xs={12} sx={{ mx: 2 }}>
								<Typography sx={{ mt: 0, borderBottom: '1px #d3d3d3 solid' }} variant="h6" component="span">
									Custom Riders
								</Typography>
								<Table sx={{ minWidth: 650 }} aria-label="simple table">
									<TableHead>
										<TableRow>
											<TableCell>Name</TableCell>
											<TableCell>Code</TableCell>
											<TableCell>Value</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{quotationData?.customRiders.map((rider, index) => (
											<TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
												<TableCell sx={{ py: 1 }}>{rider?.displayName}</TableCell>
												<TableCell sx={{ py: 1 }}>{rider?.code}</TableCell>
												<TableCell sx={{ py: 1 }}>{rider?.value}</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</Grid>
						)}
					</Grid>
				</Box>
			</DialogContent>
		</Dialog>
	);
};

export default PolicyView;
