import Cookies from 'js-cookie'
const authHeader = () => {
  const user = JSON.parse(localStorage.getItem('user'))

  if (user && user.token) {
    return { Authorization: 'Bearer ' + user.token, 'x-api-key':  'jd4q908hosdexr07ff5qp2flnjm6gafg392jgl0ad6vqh8ueby','x-xsrf-token': Cookies.get('CSRF-TOKEN') }
  } else {
    return {}
  }
}
export default authHeader
