import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Autocomplete, Container, Grid, MenuItem } from '@mui/material';
import agentService from '../../../services/agent.service';
import companiesService from '../../../services/companies.service';

export default function ExportQuotation(props) {
	const { onClose, open, type } = props;
	const [agents, setAgents] = useState([]);
	const [schemes, setSchemes] = useState([]);
	const [status, setStatus] = useState('');
	const [insurer, setInsurer] = useState('');
	const [scheme, setScheme] = useState('');
	const [date_from, setDateFrom] = useState('');
	const [date_to, setDateTo] = useState('');
	const [agent, setAgent] = useState('');
	const [companies, setCompanies] = useState([]);

	const statuses = [
		{
			value: 'QUOTATION',
			label: 'Quotation',
		},
		{
			value: 'WAITING_APPROVAL',
			label: 'Waiting Approval',
		},
		{
			value: 'REJECTED',
			label: 'Rejected',
		},
		{
			value: 'WAITING_PAYMENT',
			label: 'Waiting Payment',
		},
		{
			value: 'EXPIRED',
			label: 'Expired',
		},
		{
			value: 'COMPLETE',
			label: 'Complete',
		},
		{
			value: 'APPROVED',
			label: 'Approved',
		},
	];
	const dateNow = new Date(); // Creating a new date object with the current date and time
	const year = dateNow.getFullYear(); // Getting current year from the created Date object
	const monthWithOffset = dateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
	const month = // Setting current Month number from current Date object
		monthWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 to adjust for date input.
			? `0${monthWithOffset}`
			: monthWithOffset;
	const date =
		dateNow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
			? `0${dateNow.getUTCDate()}`
			: dateNow.getUTCDate();

	const materialDateInput = `${year}-${month}-${date}`;
	useEffect(() => {
		agentService
			.agentsList()
			.then((response) => {
				const agentsList = response.data.data.filter((el) => el.name != 'undefined');
				setAgents(agentsList);
			})
			.catch((err) => {
				// setLoading(false)
				console.log('error', err);
			});
		companiesService
			.getCompaniesList(1, 20)
			.then((response) => {
				setCompanies(response.data.data.data);
				console.log(response.data.data.data);
			})
			.catch((err) => {
				console.log(err);
			});
		agentService
			.schemesList()
			.then((response) => {
				setSchemes(response.data.schemes);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const handleStatusChanged = (event) => {
		setStatus(event.target.value);
	};
	const handleInsurerChanged = (event) => {
		setInsurer(event.target.value);
	};
	const handleSchemeChanged = (event) => {
		setScheme(event.target.value);
	};
	const handleDateFromChanged = (event) => {
		setDateFrom(event.target.value);
	};
	const handleDateToChanged = (event) => {
		setDateTo(event.target.value);
	};
	const handleExport = () => {
		let postFixUrl = 'quotations';
		if(type === 'order') {
			postFixUrl = 'quotation-orders';
		}else if(type === 'approval') {
			postFixUrl = 'quotation-orders-approved-or-paid';
		}
		let url =
			process.env.REACT_APP_QUOTATION_V2_API_URL +
			`revamp/quotation/export/${postFixUrl}?`;
		if (status) {
			url += '&status=' + status;
		}
		if (insurer) {
			url += '&insurer=' + insurer;
		}
		if (scheme) {
			url += '&scheme=' + scheme;
		}
		if (date_from && date_to) {
			url += '&date_from=' + date_from;
			url += '&date_to=' + date_to;
		} else if (date_from || date_to) {
			window.alert('Please select date from and date to');
			return false;
		}
		if (agent) {
			url += '&agent=' + agent;
		}
		console.log(url);
		window.open(url);
	};
	return (
		<div>
			<Dialog open={open} onClose={onClose}>
				<DialogTitle>Export Quotations</DialogTitle>
				<DialogContent>
					<DialogContentText>To Download choose filters.</DialogContentText>
					<Container>
						<TextField
							autoFocus
							margin="dense"
							id="status"
							fullWidth
							defaultValue={''}
							label="Select Quotation Status"
							select
							variant="standard"
							onChange={handleStatusChanged}
						>
							<MenuItem value={''}>All</MenuItem>
							{statuses.map((option) => (
								<MenuItem key={option.value} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</TextField>
						<TextField
							autoFocus
							margin="dense"
							id="insurer"
							fullWidth
							defaultValue={''}
							label="Select Insurer"
							select
							variant="standard"
							onChange={handleInsurerChanged}
						>
							<MenuItem value={''}>All</MenuItem>
							{companies.map((option) => (
								<MenuItem key={option.id} value={option.id}>
									{option.companyName}
								</MenuItem>
							))}
						</TextField>
						{schemes && (
							<Grid>
								<TextField
									autoFocus
									margin="dense"
									id="scheme"
									fullWidth
									defaultValue={''}
									label="Select Scheme"
									select
									variant="standard"
									onChange={handleSchemeChanged}
								>
									<MenuItem value={''}>All</MenuItem>
									{schemes.map((option) => (
										<MenuItem key={option} value={option}>
											{option}
										</MenuItem>
									))}
								</TextField>
							</Grid>
						)}
						<TextField
							autoFocus
							margin="dense"
							id="date_from"
							InputLabelProps={{ shrink: true }}
							label="Date From"
							type="date"
							defaultValue={''}
							fullWidth
							variant="standard"
							onChange={handleDateFromChanged}
						/>
						<TextField
							autoFocus
							margin="dense"
							id="date_to"
							InputLabelProps={{ shrink: true }}
							label="Date To"
							defaultValue={''}
							type="date"
							fullWidth
							variant="standard"
							onChange={handleDateToChanged}
						/>
						{agents && agents.length > 0 && (
							<Grid>
								<Autocomplete
									freeSolo
									id="agents"
									disableClearable
									spacing={3}
									options={agents}
									getOptionLabel={(option) => option.name || ''}
									value={null}
									onChange={(event, newValue) => {
										console.log(newValue);
										setAgent(newValue.id);
									}}
									renderOption={(props, option) => {
										return (
											<li {...props} key={option.id}>
												{option.name}
											</li>
										);
									}}
									renderInput={(params) => (
										<TextField
											fullWidth
											{...params}
											label="Search Agent"
											key={params.id}
											variant="standard"
											InputProps={{
												...params.InputProps,
												type: 'search',
											}}
										/>
									)}
								/>
							</Grid>
						)}
					</Container>
				</DialogContent>
				<DialogActions>
					<Button onClick={onClose}>Cancel</Button>
					<Button onClick={handleExport}>Export</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
