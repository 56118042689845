
import axios from 'axios'
import authHeader from './auth-header'

const API_URL = `${process.env.REACT_APP_API_URL}of-claim/`

const getClaimsList = (page = 1, limit = 3) => {
  // http://34.87.73.102/backoffice/v1/of-claim/listClaims?page=1&limit=3 (METHOD=GET)
  const url = `${API_URL}listClaims?page=${page}&limit=${limit}`
  return axios.get(url, { headers: authHeader() })
}

export default {
  getClaimsList
}
