import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import PostLogin from '../../../layout/post-login';
import ProductForm from './edit';

const ProductAdd = (props) => {
	const location = useLocation();
	const { mode, detail } = location.state;

	return (
		<PostLogin>
			<Grid container direction="row" justifyContent="space-between" alignItems="center">
				<Grid item>
					<Typography variant="h5" gutterBottom component="h5">
						Products page {mode}
					</Typography>
				</Grid>
			</Grid>

			<ProductForm mode={mode} data={detail} {...props} />
		</PostLogin>
	);
};

export default ProductAdd;
