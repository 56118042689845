import React, { useEffect, useState } from 'react';
import { Grid, InputLabel, CircularProgress, Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import CompanyService from '../../../services/companies.service';
import MuiButton from '../../../components/MuiButton';
import { TextField, Button } from '@mui/material';
import { ProductWrapper, SectionContent, FormControlCol3, FormControlCol5 } from './style';
import { LocalConvenienceStoreOutlined, Delete } from '@mui/icons-material';
import { padding } from '@mui/system';
import Cookies from 'js-cookie';

const CompanyForm = (props) => {
	const { data, mode, history } = props;
	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = useState(false);
	const [fieldValue, setFieldValue] = useState({
		companyName: '',
		companyLogoImage: null,
	});
	const [showErrorField, setShowErrorField] = useState(false);
	const [imageSelected, setImageSelected] = useState(null);

	const loadCompanyDataToSetCsrfToken = () => {
		CompanyService.getCompaniesList(1, 1)
			.then((response) => {
				Cookies.set('CSRF-TOKEN', response.headers['xsrf-token']);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		loadCompanyDataToSetCsrfToken();
		if (mode == 'edit') {
			console.log(data);
			if (data?.companyLogo) {
				setImageSelected(data?.companyLogo);
			}
			setFieldValue((current) => {
				return {
					...current,
					companyName: data?.companyName,
				};
			});
		}
	}, [data]);

	const handleFieldValue = (event) => {
		setShowErrorField(false);
		setFieldValue((prevState) => {
			return {
				...prevState,
				[event.target.name]: event.target.value,
			};
		});
	};

	const validateForm = (fields) => {
		const keys = Object.keys(fields);
		let isValid = true;
		keys.map((item) => {
			if (fields[item] == '') {
				isValid = false;
			}
		});
		return isValid;
	};

	const handleSubmit = () => {
		setLoading(true);
		setShowErrorField(true);

		const isValid = validateForm({ companyName: fieldValue.companyName });
		if (!isValid) {
			return;
		}

		let formData = new FormData();
		formData.append('companyName', fieldValue.companyName);
		if (fieldValue.companyLogoImage) {
			formData.append('companyLogoImage', fieldValue.companyLogoImage, fieldValue.companyLogoImage.name);
		}
		const xsrfToken = Cookies.get('CSRF-TOKEN');

		if (mode === 'add') {
			CompanyService.createCompany(formData, xsrfToken)
				.then((resp) => {
					setLoading(false);
					enqueueSnackbar('Changes made have been successfully saved', {
						variant: 'success',
					});
					history.push('/companies');
				})
				.catch((err) => {
					setLoading(false);
					if (err?.response?.data) {
						enqueueSnackbar(err.response.data.message, {
							variant: 'error',
						});
					} else {
						enqueueSnackbar(err.error_message, { variant: 'error' });
					}
				});
		} else {
			CompanyService.updateCompany(data?.id, formData, xsrfToken)
				.then(() => {
					setLoading(false);

					enqueueSnackbar('Changes made have been successfully saved', {
						variant: 'success',
					});
					props.history.push('/companies');
				})
				.catch((err) => {
					setLoading(false);
					if (err?.response?.data) {
						enqueueSnackbar(err.response.data.message, {
							variant: 'error',
						});
					} else {
						enqueueSnackbar(err.error_message, { variant: 'error' });
					}
				});
		}
	};

	const handleRemove = (event) => {
		setImageSelected(null);
	};

	const handleFilesChange = (event) => {
		setImageSelected(URL.createObjectURL(event.target.files[0]));
		setFieldValue((current) => {
			console.log(event.target.files[0]);
			return {
				...current,
				companyLogoImage: event.target.files[0],
			};
		});
	};

	return (
		<Grid>
			<ProductWrapper>
				<SectionContent>
					<FormControlCol5>
						<InputLabel>Company Name</InputLabel>
						<TextField
							error={Boolean(fieldValue.companyName === '') && showErrorField}
							helperText={Boolean(fieldValue.companyName === '') && showErrorField ? 'Company name is required' : ''}
							label="Company Name"
							margin="normal"
							name="companyName"
							onChange={handleFieldValue}
							type="text"
							value={fieldValue.companyName}
							variant="outlined"
						/>
					</FormControlCol5>
				</SectionContent>
				<SectionContent>
					<FormControlCol5>
						<InputLabel>Company Logo</InputLabel>
						<br />
						{!imageSelected && (
							<MuiButton variant="contained" color="success" component="label">
								Select File
								<input hidden accept="image/*" onChange={handleFilesChange} type="file" />
							</MuiButton>
						)}
						{imageSelected && (
							<MuiButton
								variant="contained"
								onClick={handleRemove}
								color="warning"
								style={{
									width: '0',
									borderRadius: '5px',
									height: '6%',
									position: 'relative',
									top: '17px',
									left: 0,
									zIndex: 1,
								}}
								component="label"
							>
								<Delete style={{ fontFamily: '10px', margin: 0, padding: '1px' }} />
							</MuiButton>
						)}
						{imageSelected && (
							<Box
								sx={{
									width: 300,
									height: 300,
									backgroundColor: 'black',
									display: 'flex',
									alignContent: 'center',
									justifyContent: 'center',
									margin: '10px',
									borderRadius: '5px',
								}}
							>
								<img style={{ width: 300, height: 300 }} src={imageSelected} />
							</Box>
						)}
					</FormControlCol5>
				</SectionContent>
			</ProductWrapper>

			<Grid container direction="row" justifyContent="start" alignItems="center" p={3}>
				<Grid item xs={2}>
					<MuiButton
						variant="contained"
						type="submit"
						onClick={handleSubmit}
						disable={loading.toString()}
						color="primary"
					>
						{loading && <CircularProgress style={{ color: 'white', margin: '2px' }} size={20} />}Submit
					</MuiButton>
				</Grid>

				{mode === 'edits' && (
					<Grid item xs={4}>
						<MuiButton
							variant="contained"
							type="button"
							onClick={() =>
								history.push({
									pathname: '/insurance-plan/add',
									state: { companyId: data?.id },
								})
							}
							disable={loading.toString()}
							color="primary"
						>
							{loading && <CircularProgress style={{ color: 'white', margin: '2px' }} size={20} />}
							Add Insurance Plan
						</MuiButton>
					</Grid>
				)}
			</Grid>
		</Grid>
	);
};

export default CompanyForm;
