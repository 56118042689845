import { styled } from '@mui/material/styles'

export const PreLoginOuterWrapper = styled('div')(`
  align-items: center;
  display: flex;
  flex-grow: 1;
  min-height: 100vh;
  background-color: #EA5F2E;
`)

export const LhsWrapper = styled('div')(`
  width: 60%;
  display: flex;
  justify-content: center;
`)

export const RhsWrapper = styled('div')(`
  padding-right: 80px;
  width:40%;
  overflow: auto;
  display: flex;
`)

export const PaperWrapper = styled('div')(`
  color: #121828;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: 0px 1px 4px rgb(100 116 139 / 12%);
  width: 100%;
  padding: 40px;
  background-color: white;
`)
