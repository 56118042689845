import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { Grid, Typography, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Confirmation } from '../../../components/Confirmation';
import PostLogin from '../../../layout/post-login';
import ProductTable from './list';
import ProductService from '../../../services/product.service';
import Loader from '../../../components/Loader';
import authService from '../../../services/auth.service';
import Cookies from 'js-cookie';

const ProductList = (props) => {
	const { enqueueSnackbar } = useSnackbar();

	const [gridLoader, setGridLoader] = useState(false);

	const [mode, setMode] = useState('');
	const [openConfirmation, setConfirmation] = useState(false);
	const [canEdit, setCanEdit] = useState(true);

	// const [openProductView, setOpenProductView] = useState(false)
	const [productData, setProductData] = useState();
	const [selectedRow, setSelectedRow] = useState({});
	const [productDataPage, setProductDataPage] = useState(0);
	const [productDataLimit, setProductDataLimit] = useState(10);

	const loadProductData = () => {
		ProductService.getProductList(productDataPage, productDataLimit)
			// PaymentService.getPaymentList(productDataPage, productDataLimit)
			.then((response) => {
				console.log(response?.data?.data);
				Cookies.set('CSRF-TOKEN', response.headers['xsrf-token']);
				setProductData(response?.data?.data);
			})
			.catch((err) => {
				if (err?.response?.data) {
					console.log(err.response.data.error_message);
					enqueueSnackbar(err.response.data.error_message, { variant: 'error' });
				} else {
					console.log(err.error_message);
					enqueueSnackbar(err.error_message, { variant: 'error' });
				}
				setProductData({ data: [] });
			});
	};

	const handleEdit = (event, row) => {
		event.stopPropagation();
		setSelectedRow(row);
		setMode('Edit');
		setGridLoader(true);
		ProductService.getProduct(row.id)
			.then((response) => {
				setGridLoader(false);
				props.history.push({
					pathname: '/products/edit',
					state: {
						detail: { ...response.data.data },
						mode: 'edit',
					},
				});
			})
			.catch((err) => {
				setConfirmation(false);
				enqueueSnackbar(
					`Some error occured while fetching ${selectedRow?.displayName || 'this product'} data.`,
					{ variant: 'error' },
					err
				);
			});
	};

	const handleDelete = (event, row) => {
		event.stopPropagation();
		setSelectedRow(row);
		setMode('Delete');
		setConfirmation(true);
	};

	const handleClose = () => {
		// setOpenProductForm(false)
		setConfirmation(false);
		setSelectedRow({});
		// setMode('Add')
	};

	const handleToggle = (event, row) => {
		const xsrfToken = Cookies.get('CSRF-TOKEN');
		ProductService.statusToggle(row?._id || row.id, !row?.isActive, xsrfToken)
			.then((response) => {
				loadProductData();
				enqueueSnackbar(`${row?.displayName}'s plan is successfully updated.`, { variant: 'success' });
			})
			.catch((err) => {
				console.log(err);
				enqueueSnackbar(
					err?.response?.data?.message || `Some error occured while updating ${row?.displayName || 'this company'}.`,
					{ variant: 'error' },
					err
				);
			});
	};

	const confirmationDeleteProductSubmit = () => {
		// setLoading(true)
		const xsrfToken = Cookies.get('CSRF-TOKEN');
		ProductService.deleteProduct(selectedRow?.id, xsrfToken)
			.then((response) => {
				setConfirmation(false);
				// setLoading(false)
				loadProductData();
				enqueueSnackbar(`${selectedRow?.displayName}'s product is Deleted.`, { variant: 'success' });
			})
			.catch((err) => {
				setConfirmation(false);
				// setLoading(false)
				enqueueSnackbar(
					`Some error occured while deleting ${selectedRow?.displayName || 'this product'}.`,
					{ variant: 'error' },
					err
				);
			});
	};

	useEffect(() => {
		loadProductData();
		const user = authService.getCurrentUser();
		if (user?.permissions && user?.permissions === 'read-only') {
			setCanEdit(false);
		}
	}, [productDataPage, productDataLimit]);

	return (
		<PostLogin>
			<Grid container direction="row" justifyContent="space-between" alignItems="center">
				<Grid item>
					<Typography variant="h5" gutterBottom component="h5">
						Products Page
					</Typography>
				</Grid>
				{canEdit && (
					<Grid item>
						<Link
							to={{
								pathname: '/products/add',
								state: {
									mode: 'add',
									detail: {},
								},
							}}
						>
							<Button variant="contained" size="small" startIcon={<AddIcon />}>
								Add Product
							</Button>
						</Link>
					</Grid>
				)}
			</Grid>
			{gridLoader && <Loader showLoader />}
			<ProductTable
				data={productData}
				handleEdit={handleEdit}
				handleDelete={handleDelete}
				setProductDataPage={setProductDataPage}
				setProductDataLimit={setProductDataLimit}
				productDataPage={productDataPage}
				productDataLimit={productDataLimit}
				handleToggle={handleToggle}
			/>

			{openConfirmation && (
				<Confirmation
					open={openConfirmation}
					title={`Are you sure you want to delete the product ${selectedRow?.displayName}?`}
					submitMsg={mode}
					onSubmit={() => {
						confirmationDeleteProductSubmit();
					}}
					onClose={handleClose}
				/>
			)}
		</PostLogin>
	);
};

export default ProductList;
