import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Typography, Grid, Select, MenuItem, InputLabel, Button, Stack, FormControl } from '@mui/material';
import TextField from '@mui/material/TextField';
import PostLogin from '../../layout/post-login';
import PaymentTable from './components/paymentTable';
import PaymentView from './components/paymentView';
import PaymentService from '../../services/payment.service';
import ProductService from '../../services/product.service';
import Loader from '../../components/Loader';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const PaymentComponent = (props) => {
	const { enqueueSnackbar } = useSnackbar();

	const [openPaymentView, setOpenPaymentView] = useState(false);
	const [paymentData, setPaymentData] = useState();
	const [selectedRow, setSelectedRow] = useState({});
	const [companyList, setCompanyList] = useState([{ id: 'SELECT ALL', companyName: 'SELECT ALL' }]);

	const [paymentDataPage, setPaymentDataPage] = useState(0);
	const [paymentDataLimit, setPaymentDataLimit] = useState(10);
	const [paymentDate, setPaymentDate] = useState(null);
	const [paymentStatus, setPaymentStatus] = useState('SELECT ALL');
	const [company, setCompany] = useState('SELECT ALL');
	const [triggerLoad, setTriggerLoad] = useState(false);

	const paymentStatusList = [
		'SELECT ALL',
		'WAITING_PAYMENT',
		'PAYMENT_DETECTED',
		'EXPIRED',
		'STATIC_TRX_EXPIRED',
		'COMPLETE',
		'WAITING_APPROVAL',
		'DECLINED',
	];

	const loadPaymentData = () => {
		PaymentService.getPaymentList(paymentDataPage, paymentDataLimit, paymentDate, paymentStatus, company)
			.then((response) => {
				setPaymentData(response?.data?.data);
			})
			.catch((err) => {
				if (err?.response?.data) {
					enqueueSnackbar(err.response.data.message, { variant: 'error' });
				} else {
					enqueueSnackbar(err.message, { variant: 'error' });
				}
			});
	};

	const loadCompany = () => {
		ProductService.getCompaniesList()
			.then((response) => {
				setCompanyList((prevState) => [...prevState, ...response?.data?.data]);
			})
			.catch((err) => {
				if (err?.response?.data) {
					enqueueSnackbar(err.response.data.message, { variant: 'error' });
				} else {
					enqueueSnackbar(err.message, { variant: 'error' });
				}
			});
	};

	const handleView = (event, row) => {
		event.stopPropagation();
		setOpenPaymentView(true);
		setSelectedRow(row);
	};

	const handleApprove = (quotationId, userId) => {
		PaymentService.approvePayment(quotationId, userId)
			.then((response) => {
				enqueueSnackbar('Payment status has been changed.', { variant: 'success' });
				setPaymentData(null);
				setTriggerLoad(!triggerLoad);
				// loadPaymentData();
			})
			.catch((err) => {
				console.log(err);
				if (err?.response?.data) {
					enqueueSnackbar('Approvement process failed.', { variant: 'error' });
				} else {
					enqueueSnackbar('Approvement process failed.', { variant: 'error' });
				}
			});
	};

	const handleDecline = (quotationId, userId, reason) => {
		PaymentService.declinePayment(quotationId, userId, reason)
			.then((response) => {
				enqueueSnackbar('Payment status has been changed.', { variant: 'success' });
				setPaymentData(null);
				setTriggerLoad(!triggerLoad);
				// loadPaymentData();
			})
			.catch((err) => {
				console.log(err);
				if (err?.response?.data) {
					enqueueSnackbar('Decline process failed.', { variant: 'error' });
				} else {
					enqueueSnackbar('Decline process failed.', { variant: 'error' });
				}
			});
	};

	const handleFilter = (e) => {
		setPaymentDataPage(0);
		setPaymentDataLimit(10);
		setPaymentData(null);
		setTriggerLoad(!triggerLoad);
	};

	const handleResetFilter = (e) => {
		setPaymentDataPage(0);
		setPaymentDataLimit(10);
		setPaymentData(null);
		setPaymentDate(null);
		setCompany('SELECT ALL');
		setPaymentStatus('SELECT ALL');
		setTriggerLoad(!triggerLoad);
	};

	const handleClose = () => {
		setOpenPaymentView(false);
		setSelectedRow({});
	};

	useEffect(() => {
		loadCompany();
	}, []);

	useEffect(() => {
		console.log('load payment data');
		loadPaymentData();
	}, [paymentDataPage, paymentDataLimit, triggerLoad]);

	return (
		<PostLogin>
			<Typography variant="h5" gutterBottom component="div">
				Payments Page{' '}
			</Typography>
			<br />
			<Grid>
				<Stack spacing={2} direction="row">
					<FormControl>
						<InputLabel id="demo-simple-select-label">Status</InputLabel>
						<Select
							label="status"
							value={paymentStatus}
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							onChange={(e) => {
								setPaymentStatus(e.target.value);
							}}
						>
							{paymentStatusList.map((status, index) => (
								<MenuItem key={index} value={status}>
									{status}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl>
						<InputLabel id="demo-simple-select-label">Company</InputLabel>
						<Select
							label="company"
							value={company}
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							onChange={(e) => {
								setCompany(e.target.value);
							}}
						>
							{companyList.map((comp, index) => (
								<MenuItem key={index} value={comp.id}>
									{comp.companyName}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl>
						<LocalizationProvider dateAdapter={AdapterMoment}>
							<DesktopDatePicker
								label="Date"
								value={paymentDate}
								onChange={(newValue) => {
									if (newValue) {
										setPaymentDate(newValue.format('yyyy-MM-DD'));
									} else {
										setPaymentDate(null);
									}
								}}
								inputFormat="yyyy-MM-DD"
								renderInput={(params) => <TextField {...params} />}
							/>
						</LocalizationProvider>
					</FormControl>
					<FormControl>
						<Button color="success" onClick={handleFilter} variant="contained">
							Filter
						</Button>
					</FormControl>
					<FormControl>
						<Button variant="contained" onClick={handleResetFilter}>
							Reset Filter
						</Button>
					</FormControl>
				</Stack>
			</Grid>
			<br />
			{!paymentData && <Loader showLoader outlinedLoader />}
			<PaymentTable
				data={paymentData}
				handleApprove={handleApprove}
				handleDecline={handleDecline}
				handleView={handleView}
				setPaymentDataPage={setPaymentDataPage}
				setPaymentDataLimit={setPaymentDataLimit}
				paymentDataPage={paymentDataPage}
				paymentDataLimit={paymentDataLimit}
			/>
			{openPaymentView && (
				<PaymentView
					data={selectedRow}
					onClose={handleClose}
					refetch={loadPaymentData}
					open={openPaymentView}
					{...props}
				/>
			)}
		</PostLogin>
	);
};

export default PaymentComponent;
