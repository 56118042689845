import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Confirmation } from '../../components/Confirmation';
import { Grid, Button, Typography, Stack, FormControl, TextField } from '@mui/material';
import PostLogin from '../../layout/post-login';
import AddIcon from '@mui/icons-material/Add';
import AgentTable from './list/userTable';
// import AgentForm from './components/agentForm';
import CarrierUserService from '../../services/carrierUser.service';
import CompanyService from '../../services/companies.service';
import Loader from '../../components/Loader';
import Cookies from 'js-cookie';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const CarrierUsersComponent = (props) => {
	const { enqueueSnackbar } = useSnackbar();

	// const [loading, setLoading] = useState(false)
	const [openAgentForm, setOpenAgentForm] = useState(false);
	const [agentData, setAgentData] = useState();
	const [selectedRow, setSelectedRow] = useState({});
	const [mode, setMode] = useState('Add');
	const [openConfirmation, setConfirmation] = useState(false);
    const [companiesList , setCompaniesList] = useState(null);
	const [agentDataPage, setAgentDataPage] = useState(0);
	const [agentDataLimit, setAgentDataLimit] = useState(10);
	const [filterDate, setFilterDate] = useState(null);
	const [triggerLoad, setTriggerLoad] = useState(false);

	const loadAgentData = () => {
		// setLoading(true)
        CompanyService.getCompaniesList(1, 100)
			.then((response) => {
                const companyListing = response?.data?.data?.data
                console.log(response)
				setCompaniesList(response?.data?.data?.data);
                CarrierUserService.agentsList(agentDataPage, agentDataLimit, filterDate)
			        .then((resp) => {
                        const agentList = resp?.data?.data
                        if(agentList){
                            const newAgentList = agentList.map(ele=> {
                                console.log(companiesList)
                                const company= companyListing.find(el=> el.id == ele.companyId)
                                if(company){
                                    ele['company'] = company
                                }else{
                                    ele['company']={newAgentList:'--'}
                                }

                               return ele 
                            })
                            setAgentData(newAgentList);
                        }
				        
			        })
			        .catch((err) => {
				        // setLoading(false)
				        if (err?.response?.data) {
					        enqueueSnackbar(err.response.data.message, { variant: 'error' });
				        } else {
					        enqueueSnackbar(err.message, { variant: 'error' });
				        }
			        });
			})
			.catch((err) => {
				setCompaniesList(null)
				if (err?.response?.data) {
					enqueueSnackbar(err.response.data.error_message, { variant: 'error' });
				} else {
					enqueueSnackbar(err.error_message, { variant: 'error' });
				}
			});
		
	};

	const handleAdd = () => {
		setOpenAgentForm(true);
		setSelectedRow({});
		setMode('Add');
	};

	const handleEdit = (event, row) => {
		event.stopPropagation();
		setOpenAgentForm(true);
		setSelectedRow(row);
		setMode('Edit');
	};

	const handleDelete = (event, row) => {
		event.stopPropagation();
		setSelectedRow(row);
		setMode('Delete');
		setConfirmation(true);
	};

	const handleClose = () => {
		setOpenAgentForm(false);
		setConfirmation(false);
		setSelectedRow({});
		setMode('Add');
	};

	const handleFilter = (e) => {
		setAgentDataPage(0);
		setAgentDataLimit(10);
		setAgentData(null);
		setTriggerLoad(!triggerLoad);
	};

	const handleResetFilter = (e) => {
		setAgentDataPage(0);
		setAgentDataLimit(10);
		setAgentData(null);
		setFilterDate(null);
		setTriggerLoad(!triggerLoad);
	};

	const handleToggle = (event, row) => {
		event.stopPropagation();
		console.log(row);
		CarrierUserService.toggleAgentStatus(row?.id, row?.isActive ? 'DeActive' : 'Active')
			.then((response) => {
				setAgentData(prev => {
					const data = [...prev]
					const findObject = data.find(e => e.id == row.id)
					findObject.isActive = !findObject.isActive

					return data
				})
				enqueueSnackbar(`Status is Successfuly updated.`, { variant: 'success' });
			})
			.catch((err) => {
				enqueueSnackbar(
					`Some error occured while updating status.`,
					{ variant: 'error' },
					err
				);
			});
	};

	const confirmationDeleteAgentSubmit = () => {
		// setLoading(true)

		// AgentService.deleteAgent(selectedRow?.id)
		// 	.then((response) => {
		// 		setConfirmation(false);
		// 		// setLoading(false)
		// 		loadAgentData();
		// 		enqueueSnackbar(`${selectedRow?.name}'s agent is Deleted.`, { variant: 'success' });
		// 	})
		// 	.catch((err) => {
		// 		setConfirmation(false);
		// 		// setLoading(false)
		// 		enqueueSnackbar(
		// 			`Some error occured while deleting ${selectedRow?.name || 'this agent'}.`,
		// 			{ variant: 'error' },
		// 			err
		// 		);
		// 	});
	};

	useEffect(() => {
		loadAgentData();
	}, [agentDataPage, agentDataLimit, triggerLoad]);

	return (
		<PostLogin>
			<Grid container direction="row" justifyContent="space-between" alignItems="center">
				<Grid item>
					<Typography variant="h5" gutterBottom component="h5">
						 Page{' '}
					</Typography>
				</Grid>
				<Grid item sx={{ display: 'none' }}>
					<Button startIcon={<AddIcon />} onClick={() => handleAdd()}>
						Add User
					</Button>
				</Grid>
			</Grid>

			<br />
			<Grid>
				<Stack spacing={2} direction="row">
					<FormControl>
						<LocalizationProvider dateAdapter={AdapterMoment}>
							<DesktopDatePicker
								label="Signup Date"
								value={filterDate}
								onChange={(newValue) => {
									if (newValue) {
										setFilterDate(newValue.format('yyyy-MM-DD'));
									} else {
										setFilterDate(null);
									}
								}}
								inputFormat="yyyy-MM-DD"
								renderInput={(params) => <TextField {...params} />}
							/>
						</LocalizationProvider>
					</FormControl>
					<FormControl>
						<Button color="success" variant="contained" onClick={handleFilter}>
							Filter
						</Button>
					</FormControl>
					<FormControl>
						<Button variant="contained" onClick={handleResetFilter}>
							Reset Filter
						</Button>
					</FormControl>
				</Stack>
			</Grid>
			<br />

			{!agentData && <Loader showLoader outlinedLoader />}
			<AgentTable
				// loading={loading}
				data={agentData}
				handleEdit={handleEdit}
				handleDelete={handleDelete}
				setAgentDataPage={setAgentDataPage}
				setAgentDataLimit={setAgentDataLimit}
				agentDataPage={agentDataPage}
				agentDataLimit={agentDataLimit}
				handleToggle={handleToggle}
			/>

			{/* {openAgentForm && (
				// <AgentForm
				// 	mode={mode}
				// 	data={selectedRow}
				// 	onClose={handleClose}
				// 	refetch={loadAgentData}
				// 	open={openAgentForm}
				// 	{...props}
				// />
			)} */}

			{openConfirmation && (
				<Confirmation
					open={openConfirmation}
					title={`Are you sure you want to delete the agent ${selectedRow?.name}?`}
					submitMsg={mode}
					onSubmit={() => {
						confirmationDeleteAgentSubmit();
					}}
					onClose={handleClose}
				/>
			)}
		</PostLogin>
	);
};

export default CarrierUsersComponent;
