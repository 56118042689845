import { styled } from '@mui/system';
import { Box, TextField } from '@mui/material';

export const FormLabel = styled('label')(`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #262928;
  width:100%;
  margin-bottom: 10px;
`);

export const TextFieldStyled = styled(TextField)(`
  & div > textarea {
    font-size: 14px;
  }
  & div > textarea::placeholder {
    font-size: 14px;
  }
  & div > input {
    font-size: 14px;
  }
  & div > input::placeholder {
    font-size: 14px;
  }
`);

export const TextFieldWrapper = styled(Box)(
	({ theme }) => `
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  margin-bottom: 8px;
  & > div {
    margin: 0;
  }
`
);
