import React, { useEffect, useState } from 'react';
import { Grid, InputLabel, CircularProgress, Box, MenuItem } from '@mui/material';
import { useSnackbar } from 'notistack';
import BannerService from '../../../services/bannerImages.service';
import MuiButton from '../../../components/MuiButton';
import { TextField, Button } from '@mui/material';
import { ProductWrapper, SectionContent, FormControlCol3, FormControlCol5 } from './style';
import { LocalConvenienceStoreOutlined, Delete } from '@mui/icons-material';
import { padding } from '@mui/system';
import Cookies from 'js-cookie';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import Alert from '@mui/material/Alert';


const BannerForm = (props) => {
	const { data, mode, history } = props;
	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = useState(false);
	const [fieldValue, setFieldValue] = useState({
		title: '',
		bannerImage: null,
		description: '',
		status:'DEACTIVE',
		imageType:'',
		deepLink: '',
		order: 0,
	});
	const [showErrorField, setShowErrorField] = useState(false);
	const [imageSelected, setImageSelected] = useState(null);
	const [bannerTypeList , setBannerTypeList] = useState([{name:"Home Screen", key:"home_screen"}]);
	const [imageExtError, setImageExtError] = useState(false);
	const orderList = [{name:"1", key:1},{name:"2", key:2},{name:"3", key:3},{name:"4", key:4}];
	const imageAllowed = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif','image/webp'];
	const loadBannerDataToSetCsrfToken = () => {
		// BannerService.(1, 1)
		// 	.then((response) => {
		// 		Cookies.set('CSRF-TOKEN', response.headers['xsrf-token']);
		// 	})
		// 	.catch((err) => {
		// 		console.log(err);
		// 	});
	};

	useEffect(() => {
		loadBannerDataToSetCsrfToken();
		if (mode == 'edit') {
			console.log(data);
			if (data?.imageUrl) {
				setImageSelected(data?.imageUrl);
			}
			setFieldValue((current) => {
				return {
					...current,
					title: data?.title,
					description: data?.description,
					imageType: data?.imageType,
					status: data?.status,
					order: data?.order,
					deepLink: data?.deepLink
				};
			});
		}
	}, [data]);

	const handleFieldValue = (event) => {
		setFieldValue((prevState) => {
			return {
				...prevState,
				[event.target.name]: event.target.value,
			};
		});
	};

	const validateForm = (fields) => {
		const keys = Object.keys(fields);
		let isValid = true;
		keys.map((item) => {
			if (fields[item] == '') {
				isValid = false;
			}
		});
		return isValid;
	};

	const handleChanges = (event) => {
		handleFieldValue(event);
	};
	

	const handleSubmit = () => {
		setLoading(true);
		setShowErrorField(false);

		const isValid = validateForm({ title: fieldValue.title, description: fieldValue.description, imageType: fieldValue.imageType, bannerImage: fieldValue.bannerImage});
		if (!isValid) {
			setShowErrorField(true);
			setLoading(false);
			return;
		}

		let formData = new FormData();
		formData.append('title', fieldValue.title);
		formData.append('description', fieldValue.description);
		if (fieldValue.bannerImage) {
			formData.append('bannerImage', fieldValue.bannerImage, fieldValue.bannerImage.name);
		}
		if(fieldValue.imageType){
			formData.append('imageType', fieldValue.imageType);
		}
		if(fieldValue.deepLink){
			formData.append('deepLink', fieldValue.deepLink);
		}
		if(fieldValue.status){
			formData.append('status', fieldValue.status);
		}


		const xsrfToken = Cookies.get('CSRF-TOKEN');

		if (mode === 'add') {
			if(!fieldValue.bannerImage){
				setShowErrorField(true);
				setLoading(false);
				return

			}
			BannerService.addBanner(formData)
				.then((resp) => {
					setLoading(false);
					enqueueSnackbar(resp.data.message ||
						'Banner created successfully', {
						variant: 'success',
					});
					history.push('/banner-images');
				})
				.catch((err) => {
					setLoading(false);
					if (err?.response?.data) {
						enqueueSnackbar(err.response.data.message, {
							variant: 'error',
						});
					} else {
						enqueueSnackbar(err.error_message, { variant: 'error' });
					}
				});
		} else {
			BannerService.updateBanner(data?._id, fieldValue)
				.then(() => {
					setLoading(false);

					enqueueSnackbar('Changes made have been successfully saved', {
						variant: 'success',
					});
					props.history.push('/banner-images');
				})
				.catch((err) => {
					setLoading(false);
					if (err?.response?.data) {
						enqueueSnackbar(err.response.data.message, {
							variant: 'error',
						});
					} else {
						enqueueSnackbar(err.error_message, { variant: 'error' });
					}
				});
		}
	};

	const handleRemove = (event) => {
		setImageSelected(null);
	};

	const handleFilesChange = (event) => {
		if (!imageAllowed.includes(event.target.files[0].type)) {
			setImageExtError(true);
			return;
		}
		setImageSelected(URL.createObjectURL(event.target.files[0]));
		setFieldValue((current) => {
			console.log(event.target.files[0]);
			return {
				...current,
				bannerImage: event.target.files[0],
			};
		});
	};

	return (
		<Grid>
			<ProductWrapper>
			{/* <Alert severity="warning">This is a warning Alert.</Alert> */}
				<SectionContent>
					<FormControlCol5>
						<InputLabel>Banner Title <span style={{ color: 'red' }}>*</span></InputLabel>
						<TextField
							error={Boolean(fieldValue.title === '') && showErrorField}
							helperText={Boolean(fieldValue.title === '') && showErrorField ? 'Banner title is required' : ''}
							label="Banner Title"
							margin="normal"
							name="title"
							onChange={handleFieldValue}
							type="text"
							value={fieldValue.title}
							variant="outlined"
						/>
					</FormControlCol5>
					</SectionContent>

					<SectionContent>
					<FormControlCol5>
						<InputLabel>Description <span style={{ color: 'red' }}>*</span></InputLabel>
						<TextField
							error={Boolean(fieldValue.description === '') && showErrorField}
							helperText={Boolean(fieldValue.description === '') && showErrorField ? 'Banner title is required' : ''}
							label="Description"
							margin="normal"
							name="description"
							onChange={handleFieldValue}
							type="text"
							value={fieldValue.description}
							variant="outlined"
						/>
					</FormControlCol5>
				</SectionContent>
				<SectionContent>
				<FormControlCol5>
						<InputLabel>
							Banner Type <span style={{ color: 'red' }}>*</span>
						</InputLabel>
						<Select
							id="banner_type"
							defaultValue="Select Banner Type"
							name="imageType"
							onChange={handleChanges}
							size="small"
							value={fieldValue.imageType}
							error={showErrorField && (fieldValue.imageType === '' || fieldValue.imageType === null)}
						>
							{bannerTypeList.map((banner, index) => (
								<MenuItem key={index} value={banner.key}>
									{banner.name}
								</MenuItem>
							))}
						</Select>
						{showErrorField && (fieldValue.imageType === '' || fieldValue.imageType === null) ? (
							<FormHelperText error>Please Select!</FormHelperText>
						) : (
							''
						)}
					</FormControlCol5>
					</SectionContent>
					<FormControlCol5>
						<InputLabel>Deep Link </InputLabel>
						<TextField
							error={Boolean(fieldValue.deepLink === '') && showErrorField}
							helperText={Boolean(fieldValue.deepLink === '') && showErrorField ? 'Deep Link is required' : ''}
							label="Deep Link"
							margin="normal"
							name="deepLink"
							onChange={handleFieldValue}
							type="text"
							value={fieldValue.deepLink}
							variant="outlined"
						/>
					</FormControlCol5>
					{ mode == 'edit' && <SectionContent>
				<FormControlCol5>
						<InputLabel>
							Banner Order 
						</InputLabel>
						{fieldValue.status === 'ACTIVE' && <Select
							id="banner_type"
							defaultValue="Select Order"
							name="order"
							onChange={handleChanges}
							size="small"
							value={fieldValue.order}
							error={showErrorField && (fieldValue.order === '' || fieldValue.order === null)}
						>
							{orderList.map((banner, index) => (
								<MenuItem key={index} value={banner.key}>
									{banner.name}
								</MenuItem>
							))}
						</Select>
						}
						{
							fieldValue.status !== 'ACTIVE' && <>
							<TextField
							label="Order"
							margin="normal"
							name="order"
							onChange={handleFieldValue}
							type="text"
							InputProps={{
								readOnly: true,
							  }}
							value={fieldValue.order}
							variant="outlined">

							</TextField>
							<small style={{color:'rgb(102, 60, 0)'}}>Note: Order cannot be changed if status is disabled</small>
							</>
						}
						
						{showErrorField && (fieldValue.order === '' || fieldValue.order === null) ? (
							<FormHelperText error>Please Select!</FormHelperText>
						) : (
							''
						)}
					</FormControlCol5>
					</SectionContent>
}
					<SectionContent>
					<FormControlCol5>
						<InputLabel>Banner Image {mode == 'add' && <span style={{ color: 'red' }}>*</span>}</InputLabel>
						<br />
						{!imageSelected && (
							<>
							<MuiButton variant="contained" color="success" component="label">
								Select File
								<input hidden accept="image/*" onChange={handleFilesChange} type="file" />
								
							</MuiButton>
							{showErrorField && (!fieldValue.bannerImage) ? (
								<FormHelperText error>Banner Image is required</FormHelperText>
							) : (
								''
							)}
							{imageExtError  ? (
								<FormHelperText error>Banner Image must of type {imageAllowed.join(',')}</FormHelperText>
							) : (
								''
							)}
							</>
						)}
						{mode == 'add' && imageSelected && (
							<>
							<MuiButton
								variant="contained"
								onClick={handleRemove}
								color="warning"
								style={{
									width: '0',
									borderRadius: '5px',
									height: '6%',
									position: 'relative',
									top: '17px',
									left: 0,
									zIndex: 1,
								}}
								component="label"
							>
								<Delete style={{ fontFamily: '10px', margin: 0, padding: '1px' }} />
							</MuiButton>
							
							</>
						)}
						{imageSelected && (
							<>
							<Box
								sx={{
									width: 300,
									height: 300,
									backgroundColor: 'black',
									display: 'flex',
									alignContent: 'center',
									justifyContent: 'center',
									margin: '10px',
									borderRadius: '5px',
								}}
							>
								<img style={{ width: 300, height: 300 }} src={imageSelected} />
							</Box>
							{mode == 'edit' && (<small style={{color:'red'}}>Note : Image cannot be updated</small>)}
							
							</>
						)}
					</FormControlCol5>
				</SectionContent>
			</ProductWrapper>

			<Grid container direction="row" justifyContent="start" alignItems="center" p={3}>
				<Grid item xs={8}>
					<MuiButton
						variant="contained"
						type="submit"
						onClick={handleSubmit}
						disable={loading.toString()}
						color="primary"
					>

						{loading && <CircularProgress style={{ color: 'white', margin: '2px' }} size={20} />}{mode == 'edit' ? 'Update' : 'Submit'}
					</MuiButton>
					<MuiButton
					style={{marginLeft: '10px'}}
						variant="contained"
						type="button"
						onClick={()=> history.goBack()}
						color="secondary"
					>Cancel
					</MuiButton>
				</Grid>
				</Grid>
				</Grid>

			
	);
};

export default BannerForm;
