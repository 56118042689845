import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

// export const ComponentName = styled('')(({theme}) => ({}))
// export const ComponentName = styled('')(({theme}) => ``);

export const PostLoginOuterWrapper = styled(Box)({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%'
})

export const LhsWrapper = styled(Box)({
  display: 'flex',
  width: '280px'
})

export const RhsWrapper = styled(Box)({
  display: 'flex',
  width: 'calc(100% - 280px)',
  flexDirection: 'column'
})

export const ContentWrapper = styled(Box)({
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  width: '100%',
  height: 'calc(100vh - 65px)',
  background: 'white',
  overflow: 'auto',
  padding: '20px'
})
