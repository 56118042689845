import React, { useState } from 'react'
import { useFormik } from 'formik'
import {
  Typography
} from '@mui/material'
import InputBase from '@mui/material/InputBase'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'
import { InputBaseWrapper, StyledIconButton } from './style'

const SearchList = props => {
  const {
    title, placeholder, onSubmit, onClear
  } = props

  const validation = values => {
    const errors = {}
    return errors
  }

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      search: ''
    },
    validate: values => validation,
    onSubmit: values => {
      const updatedData = { ...values }
      onSubmit(updatedData)
    }
  })
  // useEffect(() => {
  //   const listener = event => {
  //     if (event.code === 'Enter' || event.code === 'NumpadEnter') {
  //       console.log('Enter key was pressed. Run your function.');
  //       event.preventDefault();
  // callMyFunction();
  //     }
  //   }
  const [search, setSearch] = useState(true)
  const [clear, setClear] = useState(false)
  const searchHide = () => {
    setSearch(false)
    setClear(true)
    // console.log('seached--')
  }
  const clearSearch = () => {
    setSearch(true)
    setClear(false)
    // console.log('cleared--')
    formik.values.search = ''
  }
  return (
    <InputBaseWrapper>
      {title && <Typography variant='h6' gutterBottom component='h6'>{title}</Typography>}
      <InputBase
        error={Boolean(
          formik.touched.search && formik.errors.search
        )}
        helperText={formik.touched.search && formik.errors.search}
        margin='normal'
        name='search'
        label=''
        placeholder={placeholder}
        size='small'
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        onKeyDown={e => { if (e.key === 'Enter') { searchHide(e); formik.handleSubmit(e); console.log('enter chapse') } }}
        type='text'
        value={formik.values.search}
        variant='outlined'
        sx={{ minWidth: 230 }}
        inputProps={{ 'aria-label': 'search' }}
      />
      {search &&
        <StyledIconButton>
          <SearchIcon type='submit' aria-label='search' onClick={e => { searchHide(e); formik.handleSubmit(e) }} />
        </StyledIconButton>}
      {clear &&
        <StyledIconButton>
          <CloseIcon onClick={e => { clearSearch(e); onClear(e) }} />
        </StyledIconButton>
      }
    </InputBaseWrapper>
  )
}

export default SearchList
