import React, { useEffect, useState } from 'react';
import {
	Dialog,
	DialogContent,
	IconButton,
	Typography,
	Grid,
	List,
	ListItem,
	ListItemText,
	Box,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { toLocaleString, paymentStatusFormat } from '../../../util';
import AgentService from '../../../services/agent.service';
import moment from 'moment';

const DialogTitle = (props) => {
	const { children, onClose } = props;
	return (
		<Grid container direction="row" justifyContent="space-between" alignItems="center" p={3} pb={0}>
			<Typography variant="h5">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" onClick={onClose}>
					<Close />
				</IconButton>
			) : null}
		</Grid>
	);
};

const PaymentView = (props) => {
	const { onClose, open, data } = props;
	const commissionData = data?.commissionCalculation ? JSON.parse(data?.commissionCalculation) : {};
	const premiumData = data?.premiumCalculation ? JSON.parse(data?.premiumCalculation) : {};
	const insuranceProductData = data?.insuranceProduct || {};
	const [agentProfile, setAgentProfile] = useState(null);

	useEffect(() => {
		AgentService.agentProfile(data?.userId)
			.then((response) => {
				setAgentProfile(response.data.data);
				console.log(response.data.data);
			})
			.catch((err) => {
				// setLoading(false)
				console.log('error', err);
			});
	}, []);

	const listingFunc = (title, value) => {
		return value ? (
			<ListItem sx={{ width: '25%' }}>
				<ListItemText primary={title} secondary={value || ''} />
			</ListItem>
		) : (
			''
		);
	};

	return (
		<Dialog onClose={onClose} aria-labelledby="view-payment" open={open} maxWidth="lg" fullWidth>
			<DialogTitle id="view-payment" onClose={onClose}>
				View Payment
			</DialogTitle>
			<DialogContent>
				<Box>
					<Grid container spacing={2} sx={{ pb: 4 }}>
						<Grid item xs={12}>
							<Typography sx={{ mt: 0, mx: 2, borderBottom: '1px #d3d3d3 solid' }} variant="h6" component="h6">
								General details
							</Typography>
							<List sx={{ display: 'flex', flexWrap: 'wrap' }}>
								{listingFunc('Agent Name', agentProfile?.name)}
								{listingFunc('Customer Name', data?.customerName)}
								{listingFunc('Mobile Number', data?.mobileNumber)}
								{listingFunc('Email', data?.email)}
								{listingFunc('Payment Amount', toLocaleString(data?.paymentAmount))}
								{listingFunc('Tax Amount', toLocaleString(data?.taxAmount))}
								{listingFunc('Tax Percentage', `${data?.taxPercentage || 0}%`)}
								{listingFunc('Total Sum Assured Value', toLocaleString(data?.totalSumAssuredValue))}
								{listingFunc('Payment Status', paymentStatusFormat(data?.paymentStatus))}
								{listingFunc('Decline Reason', data?.statusReason)}
								{listingFunc('Start Date', moment(data?.startDate).format('yyyy-MM-DD'))}
								{listingFunc('End Date', moment(data?.endDate).format('yyyy-MM-DD'))}
							</List>
						</Grid>

						{commissionData && (
							<Grid item xs={12}>
								<Typography sx={{ mt: 0, mx: 2, borderBottom: '1px #d3d3d3 solid' }} variant="h6" component="h6">
									Commission Amount
								</Typography>
								<List sx={{ display: 'flex', flexWrap: 'wrap' }}>
									{listingFunc('Total Comission Amount', toLocaleString(commissionData?.totalCommisionAmount))}
									{listingFunc('Total Comission Percentage', `${commissionData?.totalCommisionPercentage || 0}%`)}
									{listingFunc('Total Premium For Comission', toLocaleString(commissionData?.totalPremiumForComision))}
								</List>
							</Grid>
						)}

						{premiumData && (
							<Grid item xs={12}>
								<Typography sx={{ mt: 0, mx: 2, borderBottom: '1px #d3d3d3 solid' }} variant="h6" component="h6">
									Premium Amount
								</Typography>
								<List sx={{ display: 'flex', flexWrap: 'wrap' }}>
									{listingFunc('Discount', toLocaleString(premiumData?.discount))}
									{listingFunc('Main Coverage', toLocaleString(premiumData?.mainCoverage))}
									{listingFunc('Net Premium', toLocaleString(premiumData?.netPremium))}
									{listingFunc('Total Premium', toLocaleString(premiumData?.totalPremium))}
									{listingFunc('Total Riders', toLocaleString(premiumData?.totalRiders))}
								</List>
							</Grid>
						)}

						{insuranceProductData && (
							<Grid item xs={12}>
								<Typography sx={{ mt: 0, mx: 2, borderBottom: '1px #d3d3d3 solid' }} variant="h6" component="h6">
									Insurance Company Details
								</Typography>
								<List sx={{ display: 'flex', flexWrap: 'wrap' }}>
									{listingFunc('Display Name', insuranceProductData?.displayName)}
									{listingFunc('Code', insuranceProductData?.code)}
									{listingFunc('Rating', insuranceProductData?.rating)}
									{listingFunc('Company Name', insuranceProductData?.company?.companyName)}
								</List>
							</Grid>
						)}

						{data?.carDetail && (
							<Grid item xs={12}>
								<Typography sx={{ mt: 0, mx: 2, borderBottom: '1px #d3d3d3 solid' }} variant="h6" component="h6">
									Car details
								</Typography>
								<List sx={{ display: 'flex', flexWrap: 'wrap' }}>
									{listingFunc('Chassis Number', data?.carDetail?.chassisNumber)}
									{listingFunc('Color', data?.carDetail?.color)}
									{listingFunc('Machine Number', data?.carDetail?.machineNumber)}
								</List>
							</Grid>
						)}

						{data?.carDetail && (
							<Grid item xs={12}>
								<Typography sx={{ mt: 0, mx: 2, borderBottom: '1px #d3d3d3 solid' }} variant="h6" component="h6">
									Vehicle details
								</Typography>
								<List sx={{ display: 'flex', flexWrap: 'wrap' }}>
									{/* {listingFunc('Vehicle Brand Id', data?.vehicleBrandId)} */}
									{listingFunc('Vehicle Condition', data?.vehicleCondition)}
									{listingFunc('Vehicle Coverage', data?.vehicleCoverage)}
									{/* {listingFunc('Vehicle Id', data?.vehicleId)} */}
									{listingFunc('Vehicle Manufacter Year', data?.vehicleManufacterYear)}
									{listingFunc('Vehicle Usage', data?.vehicleUsage)}
									{listingFunc('Vehicle Name', data?.vehicleName)}
									{listingFunc('Vehicle Category', data?.vehicleCategory)}
									{listingFunc('Vehicle Price', toLocaleString(data?.vehiclePrice))}
								</List>
							</Grid>
						)}

						{data?.quotationFiles && (
							<Grid item xs={12} sx={{ mx: 2 }}>
								<Typography sx={{ mt: 0, borderBottom: '1px #d3d3d3 solid' }} variant="h6" component="h6">
									Document
								</Typography>
								<Table sx={{ minWidth: 650 }} aria-label="simple table">
									<TableHead>
										<TableRow>
											<TableCell>Name</TableCell>
											<TableCell>File</TableCell>
											<TableCell>Action</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										<TableRow key={4} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
											<TableCell sx={{ py: 1 }}>{`Registration Certificate`}</TableCell>
											<TableCell sx={{ py: 1 }}>
												<img src={data?.quotationFiles?.registrationCertificate} width="80px" />
											</TableCell>
											<TableCell sx={{ py: 1 }}>
												<a target="_blank" href={data?.quotationFiles?.registrationCertificate}>
													Download
												</a>
											</TableCell>
										</TableRow>

										<TableRow key={4} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
											<TableCell sx={{ py: 1 }}>{`Identity Document`}</TableCell>
											<TableCell sx={{ py: 1 }}>
												<img src={data?.quotationFiles?.identityDocument} width="80px" />
											</TableCell>
											<TableCell sx={{ py: 1 }}>
												<a target="_blank" href={data?.quotationFiles?.identityDocument}>
													Download
												</a>
											</TableCell>
										</TableRow>
										{/* Front */}
										<TableRow key={1} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
											<TableCell sx={{ py: 1 }}>{`Front Vehicle`}</TableCell>
											<TableCell sx={{ py: 1 }}>
												<img src={data?.quotationFiles?.vehicleFrontImage} width="80px" />
											</TableCell>
											<TableCell sx={{ py: 1 }}>
												<a target="_blank" href={data?.quotationFiles?.vehicleFrontImage}>
													Download
												</a>
											</TableCell>
										</TableRow>

										{/* Back */}
										<TableRow key={2} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
											<TableCell sx={{ py: 1 }}>{`Back Vehicle`}</TableCell>
											<TableCell sx={{ py: 1 }}>
												<img src={data?.quotationFiles?.vehicleBackImage} width="80px" />
											</TableCell>
											<TableCell sx={{ py: 1 }}>
												<a target="_blank" href={data?.quotationFiles?.vehicleBackImage}>
													Download
												</a>
											</TableCell>
										</TableRow>

										{/* Left */}
										<TableRow key={3} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
											<TableCell sx={{ py: 1 }}>{`Left Vehicle`}</TableCell>
											<TableCell sx={{ py: 1 }}>
												<img src={data?.quotationFiles?.vehicleLeftImage} width="80px" />
											</TableCell>
											<TableCell sx={{ py: 1 }}>
												<a target="_blank" href={data?.quotationFiles?.vehicleLeftImage}>
													Download
												</a>
											</TableCell>
										</TableRow>

										{/* Left */}
										<TableRow key={4} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
											<TableCell sx={{ py: 1 }}>{`Right Vehicle`}</TableCell>
											<TableCell sx={{ py: 1 }}>
												<img src={data?.quotationFiles?.vehicleRightImage} width="80px" />
											</TableCell>
											<TableCell sx={{ py: 1 }}>
												<a target="_blank" href={data?.quotationFiles?.vehicleRightImage}>
													Download
												</a>
											</TableCell>
										</TableRow>

										<TableRow key={4} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
											<TableCell sx={{ py: 1 }}>{`Dashboard`}</TableCell>
											<TableCell sx={{ py: 1 }}>
												<img src={data?.quotationFiles?.dashboardImage} width="80px" />
											</TableCell>
											<TableCell sx={{ py: 1 }}>
												<a target="_blank" href={data?.quotationFiles?.dashboardImage}>
													Download
												</a>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</Grid>
						)}

						{data?.customRiders && (
							<Grid item xs={12} sx={{ mx: 2 }}>
								<Typography sx={{ mt: 0, borderBottom: '1px #d3d3d3 solid' }} variant="h6" component="h6">
									Custom Riders
								</Typography>
								<Table sx={{ minWidth: 650 }} aria-label="simple table">
									<TableHead>
										<TableRow>
											<TableCell>Name</TableCell>
											<TableCell>Code</TableCell>
											<TableCell>Value</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{data?.customRiders.map((rider, index) => (
											<TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
												<TableCell sx={{ py: 1 }}>{rider?.displayName}</TableCell>
												<TableCell sx={{ py: 1 }}>{rider?.code}</TableCell>
												<TableCell sx={{ py: 1 }}>{rider?.value}</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</Grid>
						)}
					</Grid>
				</Box>
			</DialogContent>
		</Dialog>
	);
};

export default PaymentView;
