import axios from 'axios';
import authHeader from './auth-header';

const API_URL = `${process.env.REACT_APP_API_URL}`;

const loginApi = (data) => {
	const url = `${API_URL}users/login`;
	return axios.post(url, data).then((response) => {
		if (response?.data?.data?.token) {
			localStorage.setItem('user', JSON.stringify(response?.data?.data));
		}
		return response?.data?.message;
	});
};

const logout = () => {
	const url = `${API_URL}users/logout`;
	const headers = authHeader();
	localStorage.removeItem('user');
	return axios.get(url, { headers: headers });
};

const getCurrentUser = () => {
	return JSON.parse(localStorage.getItem('user'));
};

export default {
	logout,
	getCurrentUser,
	loginApi,
};
