import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { Grid, Typography, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Confirmation } from '../../../components/Confirmation';
import PostLogin from '../../../layout/post-login';
import List from './list';
// import ProductForm from '../add/add'
import BannerService from '../../../services/bannerImages.service';
import authService from '../../../services/auth.service';
import Cookies from 'js-cookie';

const BannerImagesList = (props) => {
	const { enqueueSnackbar } = useSnackbar();
	const [mode, setMode] = useState('');
	const [openConfirmation, setConfirmation] = useState(false);
	const [bannerData, setBannerData] = useState();
	const [selectedRow, setSelectedRow] = useState({});
	const [bannerDataPage, setBannerDataPage] = useState(0);
	const [bannerDataLimit, setBannerDataLimit] = useState(10);
	const [canEdit, setCanEdit] = useState(true);

	const loadBannerData = () => {
		BannerService.bannersList(bannerDataPage, bannerDataLimit)
			.then((response) => {
				Cookies.set('CSRF-TOKEN', response.headers['xsrf-token']);
				setBannerData(response?.data?.data);
			})
			.catch((err) => {
				setBannerData({ data: { data: null } });
				if (err?.response?.data) {
					enqueueSnackbar(err.response.data.error_message, { variant: 'error' });
				} else {
					enqueueSnackbar(err.error_message, { variant: 'error' });
				}
			});
	};

	const handleEdit = (event, row) => {
		event.stopPropagation();
		setSelectedRow(row);
		setMode('Edit');
		console.log({
			pathname: '/banner-image/edit',
			state: { detail: row },
		});
		props.history.push({
			pathname: '/banner-image/edit',
			state: { detail: row },
		});
	};

	const handleDisable = (event, row) => {
		event.stopPropagation();
		setSelectedRow(row);
		setMode('Delete');
		setConfirmation(true);
	};

	const handleToggle = (event, row) => {
		event.stopPropagation();
		console.log(row);
		BannerService.toggleBanner(row?._id)
			.then((response) => {
				loadBannerData();
				enqueueSnackbar(`${row?.title}'s banner is status updated.`, { variant: 'success' });
			})
			.catch((err) => {
				console.log(err.response.data.message);
				enqueueSnackbar(
					err.response.data.message || `Some error occured while updating status of ${row?.title || 'this banner'}.`,
					{ variant: 'error' },
					err
				);
			});
	};

	const handleClose = () => {
		setConfirmation(false);
		setSelectedRow({});
		// setMode('Add')
	};


	const confirmationDelete = () => {
		// setLoading(true)
		BannerService.deleteBanner(selectedRow?._id)
			.then((response) => {
				setConfirmation(false);
				// setLoading(false)
				loadBannerData();
				enqueueSnackbar(`${selectedRow?.title}'s banner is Deleted.`, { variant: 'success' });
			})
			.catch((err) => {
				setConfirmation(false);
				// setLoading(false)
				enqueueSnackbar(
					`Some error occured while deleting ${selectedRow?.title || 'this banner'}.`,
					{ variant: 'error' },
					err
				);
			});
	};

	useEffect(() => {
		loadBannerData();
		const user = authService.getCurrentUser();
		if (user?.permissions && user?.permissions === 'read-only') {
			setCanEdit(false);
		}
	}, [bannerDataPage, bannerDataLimit]);

	return (
		<PostLogin>
			<Grid container direction="row" justifyContent="space-between" alignItems="center">
				<Grid item>
					<Typography variant="h5" gutterBottom component="h5">
						Banner Page
					</Typography>
				</Grid>
				{canEdit && (
					<Grid item>
						<Link to="/banner-image/add">
							<Button variant="contained" size="small" startIcon={<AddIcon />}>
								Add Banner
							</Button>
						</Link>
					</Grid>
				)}
			</Grid>
			<List
				data={bannerData}
				handleEdit={handleEdit.bind(null)}
				handleDisable={handleDisable.bind(null)}
				setBannerDataPage={setBannerDataPage}
				setBannerDataLimit={setBannerDataLimit}
				bannerDataPage={bannerDataPage}
				bannerDataLimit={bannerDataLimit}
				handleToggle={handleToggle.bind(null)}
			/>

			{/* {
        openProductForm &&
          <ProductForm
            mode={mode}
            data={selectedRow}
            onClose={handleClose}
            refetch={loadcompanyData}
            open={openProductForm}
            {...props}
          />
      } */}

			{openConfirmation && (
				<Confirmation
					open={openConfirmation}
					title={`Are you sure you want to delete the banner ${selectedRow?.title}?`}
					submitMsg={mode}
					onSubmit={() => {
						confirmationDelete();
					}}
					onClose={handleClose}
				/>
			)}
		</PostLogin>
	);
};

export default BannerImagesList;
