import { styled } from '@mui/system'
import { InputBase, Grid } from '@mui/material'
import IconButton from '@mui/material/IconButton'

export const InputBaseWrapper = styled(Grid)(({ theme }) => (`
    padding: ${theme.components.MuiButton.styleOverrides.sizeXSmall.padding};
    border: 1px solid ${theme.components.MuiOutlinedInput.styleOverrides.notchedOutline.borderColor};
    border-radius: ${theme.shape.round.borderRadius};
`))

export const StyledIconButton = styled(IconButton)(({ theme }) => (`
    padding: 10px;
    margin-right: -15px;
`))

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  marginRight: '0'
}))
