import { styled } from '@mui/system'
import { Box } from '@mui/material'

export const Wrapper = styled(Box)(`
    display: flex;
    position: absolute;
    z-index: 5;
    background-color: rgba(255, 255, 255, 0.5);
    width: 100vw;
    height: 100%;
    cursor: progress;
`)
