import React, { useState, useEffect } from 'react';
import {
	IconButton,
	TableCell,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Paper,
	Tooltip,
	Button,
} from '@mui/material';
import BlockIcon from '@mui/icons-material/Block';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import { policyStatusFormat, policyActiveStatusFormat } from '../../../util';
import Loader from '../../../components/Loader';
import authService from '../../../services/auth.service';

const PolicyTable = ({
	data,
	handleView,
	selectedRowId,
	setPolicyDataPage,
	setPolicyDataLimit,
	policyDataPage,
	policyDataLimit,
	handleDecline,
}) => {
	const [gridLoader, setGridLoader] = useState(false);
	const [openDeclineDialog, setOpenDeclineDialog] = useState(false);
	const [selectedRow, setSelectedRow] = useState({
		quotationId: null,
		userId: null,
		declineReason: '',
	});
	const [canEdit, setCanEdit] = useState(true);

	const handleValueChanged = (event) => {
		setSelectedRow((current) => {
			return {
				...current,
				[event.target.name]: event.target.value,
			};
		});
	};

	const handleDeclineButton = (quotationId, userId) => {
		setSelectedRow((current) => {
			return {
				...current,
				quotationId,
				userId,
			};
		});
		setOpenDeclineDialog(true);
	};

	useEffect(() => {
		setGridLoader(false);
		const user = authService.getCurrentUser();
		if (user?.permissions && user?.permissions === 'read-only') {
			setCanEdit(false);
		}
	}, [data?.data]);

	const handleChangePage = (event, newPage) => {
		setGridLoader(true);
		setPolicyDataPage(newPage + 1);
	};

	const handleChangeRowsPerPage = (event) => {
		setGridLoader(true);
		setPolicyDataPage(0);
		setPolicyDataLimit(+event.target.value);
	};

	return (
		<>
			<Paper sx={{ position: 'relative', overflowX: 'clip' }}>
				{data?.currentPageRecords === 0 && <Loader message={data} />}
				{data?.currentPageRecords && (
					<>
						{(!data?.data || gridLoader) && <Loader showLoader />}
						<TableContainer>
							<Table stickyHeader aria-label="sticky table">
								<TableHead>
									<TableRow>
										<TableCell align="left">policy Number</TableCell>
										<TableCell align="left">payment method</TableCell>
										<TableCell align="left">bank Name</TableCell>
										<TableCell align="left">agent Name</TableCell>
										<TableCell align="left">Insured Name</TableCell>
										<TableCell align="left">settlement Status</TableCell>
										<TableCell align="center">Policy Status</TableCell>
										<TableCell align="center">Action</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{data?.data?.map((row, index) => {
										return (
											<TableRow hover role="checkbox" key={index}>
												<TableCell align="left">{row?.policyIdNumber || row?.policyNumber}</TableCell>
												<TableCell align="left">{row?.paymentMethod}</TableCell>
												<TableCell align="left">{row?.bankName}</TableCell>
												<TableCell align="left">{row?.agentName}</TableCell>
												<TableCell align="left">{row?.insuredName}</TableCell>
												<TableCell align="left">{policyStatusFormat(row?.settlementStatus)}</TableCell>
												<TableCell align="center">
													{policyActiveStatusFormat(row?.isActive, row?.declineReason)}
												</TableCell>
												<TableCell align="center">
													<Tooltip title="View">
														<IconButton size="small" onClick={(event) => handleView(event, row)}>
															{selectedRowId === row?.id && <Loader showLoader outlinedLoader />}
															{!selectedRowId && <ArticleOutlinedIcon fontSize="small" />}
														</IconButton>
													</Tooltip>
													{row?.isActive === true && canEdit && (
														<Tooltip title="Decline">
															<IconButton
																color="warning"
																size="small"
																onClick={(event) => handleDeclineButton(row?.quotationId, row?.userId)}
															>
																<BlockIcon fontSize="small" />
															</IconButton>
														</Tooltip>
													)}
												</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</TableContainer>
						<TablePagination
							rowsPerPageOptions={[10, 25, 50, 100]}
							component="div"
							count={data.totalPolicies}
							rowsPerPage={data.currentPageRecords}
							page={policyDataPage === 0 ? 0 : policyDataPage - 1}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</>
				)}
			</Paper>
			<Dialog
				open={openDeclineDialog}
				onClose={() => {
					setOpenDeclineDialog(false);
				}}
			>
				{/* <DialogTitle>Decline Reason</DialogTitle> */}
				<DialogContent>
					{/* <DialogContentText>Please write delcine reason.</DialogContentText> */}
					<TextField
						autoFocus
						margin="dense"
						id="declineReason"
						label="Reason"
						name="declineReason"
						type="text"
						onChange={handleValueChanged}
						fullWidth
						variant="standard"
					/>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => {
							setOpenDeclineDialog(false);
						}}
					>
						Cancel
					</Button>
					<Button
						onClick={() => {
							handleDecline(selectedRow.quotationId, selectedRow.userId, selectedRow.declineReason);
							setOpenDeclineDialog(false);
						}}
					>
						Submit
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default PolicyTable;
