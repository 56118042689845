import axios from 'axios';
import authHeader from './auth-header';

const API_URL = `${process.env.REACT_APP_API_URL}`;

const PRODUCT_API_URL = `${process.env.REACT_APP_PRODUCTS_API_URL}`;
const PRODUCT_LIST_API_URL = `${process.env.REACT_APP_PRODUCTS_LIST_API_URL}`;
const QUOTATION_API_URL = `${process.env.REACT_APP_QUOTATION_API_URL}`;

const getCompaniesList = () => {
	const url = `${PRODUCT_API_URL}fetchCompanies`;
	return axios.get(url, { headers: authHeader() });
};

const getInitData = () => {
	const url = `${QUOTATION_API_URL}backoffice/productOnBoardInit`;
	return axios.get(url, { headers: authHeader() });
};

const getInsurancePlanList = (companyID) => {
	const url = `${PRODUCT_API_URL}fetchInsurancePlan/${companyID}`;
	return axios.get(url, { headers: authHeader() });
};

const getProductList = (page = 1, limit = 10) => {
	page += 1;
	const url = `${PRODUCT_LIST_API_URL}product/all?page=${page}&limit=${limit}`;
	return axios.get(url, { headers: authHeader() });
};

const getProduct = (id) => {
	const url = `${PRODUCT_LIST_API_URL}products-onboard/${id}`;
	return axios.get(url, { headers: authHeader() });
};

const createProduct = (data, xsrfToken) => {
	const url = `${PRODUCT_LIST_API_URL}products-onboard`;
	return axios.post(url, data, { headers: { ...authHeader(), 'x-xsrf-token': xsrfToken } });
};

const updateProduct = (id, data, xsrfToken) => {
	console.log(data);
	const url = `${PRODUCT_LIST_API_URL}products-onboard/${id}`;
	return axios.put(url, data, { headers: { ...authHeader(), 'x-xsrf-token': xsrfToken } });
};

const deleteProduct = (id, xsrfToken) => {
	const url = `${PRODUCT_LIST_API_URL}products-onboard/${id}`;
	return axios.delete(url, { headers: { ...authHeader(), 'x-xsrf-token': xsrfToken } });
};

const statusToggle = (id, status, xsrfToken) => {
	const url = `${PRODUCT_LIST_API_URL}products-onboard/${id}/changeStatus`;
	return axios.put(url, { status: status }, { headers: { ...authHeader(), 'x-xsrf-token': xsrfToken } });
};

export default {
	getInitData,
	getCompaniesList,
	getInsurancePlanList,
	getProductList,
	deleteProduct,
	updateProduct,
	createProduct,
	getProduct,
	statusToggle,
};
