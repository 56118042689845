import React, { useState, useEffect } from 'react';
import {
	IconButton,
	TableCell,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Paper,
	Tooltip,
	Switch,
} from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Loader from '../../../components/Loader';
import moment from 'moment';

const AgentTable = ({
	data,
	handleEdit,
	handleDelete,
	setAgentDataPage,
	setAgentDataLimit,
	agentDataPage,
	agentDataLimit,
	handleToggle,
}) => {
	const [gridLoader, setGridLoader] = useState(false);

	useEffect(() => {
		setGridLoader(false);
	}, [data?.agents]);

	const handleChangePage = (event, newPage) => {
		setGridLoader(true);
		setAgentDataPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setGridLoader(true);
		setAgentDataPage(0);
		setAgentDataLimit(+event.target.value);
	};

	

	return (
		<Paper sx={{ position: 'relative', overflowX: 'clip' }}>
			{data?.length === 0 && <Loader message={data} />}
			{data?.length && (
				<>
					{(!data || gridLoader) && <Loader showLoader />}
					<TableContainer>
						<Table stickyHeader aria-label="sticky table">
							<TableHead>
								<TableRow>
									<TableCell align="left">Name</TableCell>
									<TableCell align="left">email</TableCell>
									<TableCell align="left">role</TableCell>
                                    <TableCell align="left">company</TableCell>
									<TableCell align="left">Active</TableCell>
									<TableCell align="left">sign up date</TableCell>
									{/* <TableCell align="center">Action</TableCell> */}
								</TableRow>
							</TableHead>
							<TableBody>
								{data?.map((row) => {
									return (
										<TableRow hover role="checkbox" key={row?.id}>
											<TableCell align="left">{row?.name}</TableCell>
											<TableCell align="left">{row?.email}</TableCell>
											<TableCell align="left">{row?.role}</TableCell>
                                            <TableCell align="left">{row?.company?.companyName || '--'}</TableCell>
											<TableCell align="left">{
											// row?.isActive ? 'Active' : 'Not-Active'
											<Switch
											checked={row?.isActive}
											onChange={(event) => handleToggle(event, row)}
											name="loading"
											color="primary"
											key={row?.id}
											/>
										}</TableCell>
											<TableCell align="left">{moment(row?.createdAt).format('yyyy-MM-DD')}</TableCell>
											{/* <TableCell align="center">
												<Tooltip title="Edit">
													<IconButton size="small" onClick={(event) => handleEdit(event, row)}>
														<EditOutlinedIcon fontSize="small" />
													</IconButton>
												</Tooltip>
												<Tooltip title="Delete">
													<IconButton size="small" onClick={(event) => handleDelete(event, row)}>
														<DeleteOutlinedIcon fontSize="small" />
													</IconButton>
												</Tooltip>
											</TableCell> */}
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[10, 25, 50, 100]}
						component="div"
						count={data.length}
						rowsPerPage={agentDataLimit}
						page={agentDataPage}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</>
			)}
		</Paper>
	);
};

export default AgentTable;
