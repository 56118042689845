import { styled } from '@mui/material/styles'

export const ListWrapper = styled('ul')(`
    list-style: none;
    padding: 0 0 40px 0;
    display:flex;
    flex-wrap:wrap;
    font-size: 13px;
    width:100%;
`)
export const List = styled('li')(`
    width: 25%;
    padding:10px 0;
    box-sizing:border-box;
    border-bottom: 1;
    text-align:left;
`)
