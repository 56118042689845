import axios from 'axios';
import authHeader from './auth-header';
import Cookies from 'js-cookie'


const API_URL = `${process.env.REACT_APP_API_URL}`;



const bannersList = (page, limit) => {
	return axios.get(`${API_URL}banner-images/get-all?page=${page?page+1:1}&limit=${limit?limit:10}`, { headers: authHeader() });
};

const updateBanner = (id, body) => {
	return axios.put(`${API_URL}banner-images/${id}`,body, { headers: authHeader(), 'x-xsrf-token': Cookies.get('CSRF-TOKEN')});
}

const addBanner = (body) => {
	return axios.post(`${API_URL}banner-images/create`,body, { headers: authHeader(), 'x-xsrf-token': Cookies.get('CSRF-TOKEN')});
}
const deleteBanner = (id) => {
	return axios.delete(`${API_URL}banner-images/${id}`, { headers: authHeader(), 'x-xsrf-token': Cookies.get('CSRF-TOKEN')});
}

const toggleBanner = (id) => {
	return axios.put(`${API_URL}banner-images/toggle-status/${id}`, {}, { headers: authHeader(), 'x-xsrf-token': Cookies.get('CSRF-TOKEN')});
}


export default {
	bannersList,
	updateBanner,
    addBanner,
    deleteBanner,
	toggleBanner
};
