import React from 'react'
import AuthService from '../../services/auth.service'
import PostLogin from '../../layout/post-login'
import { Typography } from '@mui/material'

const Profile = () => {
  const currentUser = AuthService.getCurrentUser()

  return (
    <PostLogin>
      <Typography variant='h5'>
        <strong>{currentUser.name}</strong> Profile
      </Typography>
      <p>
        <strong>Token:</strong> {currentUser.token.substring(0, 20)} ...{' '}
        {currentUser.token.substr(currentUser.token.length - 20)}
      </p>
      <p>
        <strong>Id:</strong> {currentUser.id}
      </p>
      <p>
        <strong>Email:</strong> {currentUser.email}
      </p>
      <strong>Authorities:</strong>
      {currentUser.roles &&
        currentUser.roles.map((role, index) => <p key={index}>{role}</p>)}
    </PostLogin>
  )
}

export default Profile
