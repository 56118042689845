import React, { useState, useEffect } from 'react';
import {
	IconButton,
	TableCell,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Paper,
	Tooltip,
	Chip,
	Switch,
} from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import Loader from '../../../components/Loader';
import authService from '../../../services/auth.service';

const List = ({
	data,
	handleDelete,
	setcompanyDataPage,
	setcompanyDataLimit,
	companyDataPage,
	companyDataLimit,
	handleToggle,
}) => {
	const [gridLoader, setGridLoader] = useState(false);
	const [canEdit, setCanEdit] = useState(true);

	useEffect(() => {
		setGridLoader(false);
		const user = authService.getCurrentUser();
		if (user?.permissions && user?.permissions === 'read-only') {
			setCanEdit(false);
		}
	}, [data]);

	const handleChangePage = (event, newPage) => {
		setGridLoader(true);
		setcompanyDataPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setGridLoader(true);
		setcompanyDataPage(0);
		setcompanyDataLimit(+event.target.value);
	};

	return (
		<Paper sx={{ position: 'relative', overflowX: 'clip' }}>
			<>
				{(!data || gridLoader) && <Loader showLoader />}

				<TableContainer>
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow>
								<TableCell align="left">Display Name</TableCell>
								<TableCell align="left">Plan Name</TableCell>
								{/* <TableCell align='left'>Code</TableCell> */}
								{/* <TableCell align='left'>Sub Plan Name</TableCell> */}

								<TableCell align="left">Company Name</TableCell>
								{canEdit && <TableCell align="left">Toggle</TableCell>}
								<TableCell align="left">Status</TableCell>
								{canEdit && <TableCell align="center">Action</TableCell>}
							</TableRow>
						</TableHead>
						<TableBody>
							{(!data || data?.data.length == 0) && (
								<TableRow hover role="checkbox">
									<TableCell align="center" colSpan={6}>
										No Data found
									</TableCell>
								</TableRow>
							)}
							{data?.data.map((row) => (
								<TableRow hover role="checkbox" key={row?._id}>
									<TableCell align="left">{row?.displayName}</TableCell>
									<TableCell align="left">{row?.planName}</TableCell>
									{/* <TableCell align='left'>{row?.code}</TableCell> */}
									{/* <TableCell align='left'>{row?.subPlanName}</TableCell> */}

									<TableCell align="left">{row?.company?.companyName}</TableCell>
									{canEdit && (
										<TableCell align="left">
											<Switch
												checked={row?.isActive}
												onChange={(event) => handleToggle(event, row)}
												name="loading"
												color="primary"
												key={row?.id}
											/>
										</TableCell>
									)}
									<TableCell align="left">
										{/* {row?.isActive ? "Enabled" : "Disbaled"} */}
										<Chip
											label={row?.isActive ? 'Enabled' : 'Disabled'}
											size="small"
											color={row?.isActive ? 'success' : 'primary'}
										/>
									</TableCell>
									{canEdit && (
										<TableCell align="center">
											<Tooltip title="Delete">
												<IconButton size="small" onClick={(event) => handleDelete(event, row)}>
													<DeleteOutlinedIcon fontSize="small" />
												</IconButton>
											</Tooltip>
										</TableCell>
									)}
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[10, 25, 50, 100]}
					component="div"
					count={data?.totalRecords || 10}
					rowsPerPage={companyDataLimit}
					page={companyDataPage}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</>
		</Paper>
	);
};

export default List;
