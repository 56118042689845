import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_COMPANY_API_URL;

const getCompaniesList = (page = 1, limit = 10) => {
	const url = `${API_URL}?page=${page}&limit=${limit}`;
	return axios.get(url, { headers: authHeader() });
};

const createCompany = (data, xsrfToken) => {
	const headers = { ...authHeader(), 'Content-Type': 'multipart/form-data', 'x-xsrf-token': xsrfToken };
	const url = `${API_URL}`;
	return axios.post(url, data, { headers });
};

const updateCompany = (id, data, xsrfToken) => {
	const url = `${API_URL}/${id}`;
	const headers = { ...authHeader(), 'Content-Type': 'multipart/form-data', 'x-xsrf-token': xsrfToken };
	return axios.put(url, data, { headers });
};

const deleteCompany = (id, xsrfToken) => {
	const url = `${API_URL}/${id}`;
	return axios.delete(url, { headers: { ...authHeader(), 'x-xsrf-token': xsrfToken } });
};

const toggleCompanyStatus = (id, status, xsrfToken) => {
	const url = `${API_URL}/${id}/changeStatus`;
	return axios.put(url, { status }, { headers: { ...authHeader(), 'x-xsrf-token': xsrfToken } });
};

export default {
	getCompaniesList,
	deleteCompany,
	updateCompany,
	createCompany,
	toggleCompanyStatus,
};
