import axios from 'axios';
import authHeader from './auth-header';
import Cookies from 'js-cookie'


const USERS_LIST_URL = `${process.env.REACT_APP_URL}/v1/carrier-user/all`;
const SCHEME_LIST_URL = `${process.env.REACT_APP_URL}/v1/insurer-scheme/scheme/list`;
const USERS_TOGGLE_URL = `${process.env.REACT_APP_URL}/v1/carrier-user/changeStatus/`


const agentsList = (page, limit) => {
	return axios.get(`${USERS_LIST_URL}?skip='${page?page:1}&limit=${limit?limit:10}`, { headers: authHeader() });
};

const toggleAgentStatus = (id, status) => {
	return axios.patch(`${USERS_TOGGLE_URL}${id}`,{"status": status}, { headers: authHeader(), 'x-xsrf-token': Cookies.get('CSRF-TOKEN')});
}


export default {
	agentsList,
	toggleAgentStatus
};
