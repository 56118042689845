import { styled } from '@mui/material/styles'
import {
  Button,
  Box,
  AppBar
} from '@mui/material'

export const IconButtonStyled = styled(Button)(
  ({ theme }) => `
        color: ${theme.overrides.MuiButton.palette.neutral};
        min-width: 0;
        max-width: 38px;
`)

export const BoxStyled = styled(Box)(`
    padding-left: 10px;
`)

export const ToolbarStyled = styled(Box)(`
    justify-content: flex-end;
    display: flex;
    min-height: 64px;
    padding: 0 10px;
    align-items: center;
`)

export const DashboardNavbarRoot = styled(AppBar)(({ theme }) => (`
    background-color: ${theme.palette.background.paper};
    box-shadow: ${theme.shadows[3]};
    position: static;
`))
