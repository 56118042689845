import axios from 'axios';
import authHeader from './auth-header';
import authService from './auth.service';

const QUOTATION_URL = `${process.env.REACT_APP_QUOTATION_API_URL}`;

const getQuotationList = (page = 1, limit = 10, quotationNo = '', customerName = '') => {
	let url = `${QUOTATION_URL}backoffice/quotations?page=${page}&limit=${limit}`;
	if (quotationNo) {
		url += `&quotationNo=${quotationNo}`;
	}
	if (customerName) {
		url += `&customerName=${customerName}`;
	}
	return axios.get(url, { headers: authHeader() });
};

const getWaitingApprovalQuotationList = (
	page = 1,
	limit = 10,
	quotationNo = '',
	customerName = '',
	quotationId = ''
) => {
	let url = `${QUOTATION_URL}backoffice/quotation-list/waiting-approvals?page=${page}&limit=${limit}`;
	if (quotationNo) {
		url += `&quotationNo=${quotationNo}`;
	}
	if (customerName) {
		url += `&customerName=${customerName}`;
	}
	if (quotationId) {
		url += `&quotationId=${quotationId}`;
	}
	return axios.get(url, { headers: authHeader() });
};

const approveQuotation = (quotationId) => {
	let url = `${QUOTATION_URL}revamp/quotation/${quotationId}/change-quotation-status`;
	const user = authService.getCurrentUser();
	const data = {
		paymentStatus: 'WAITING_PAYMENT',
		updatedBy: `${user?.name} - Backoffice Admin`,
		updatedById: user?.id,
	};
	return axios.put(url, data, { headers: authHeader() });
};
const declineQuotation = (quotationId, declineReason) => {
	const user = authService.getCurrentUser();
	const data = {
		paymentStatus: 'REJECTED',
		statusReason: declineReason,
		updatedBy: `${user?.name} - Backoffice Admin`,
		updatedById: user?.id,
	};
	console.log(data);
	let url = `${QUOTATION_URL}revamp/quotation/${quotationId}/change-quotation-status`;
	return axios.put(url, data, { headers: authHeader() });
};

const deleteQuotation = (quotationId) => {
	let url = `${QUOTATION_URL}revamp/quotation/${quotationId}/deleteQuotation`;
	return axios.delete(url, { headers: authHeader() });
};

const getInitialDamagesByQuotationId = (quotationId) => {
	let url = `${QUOTATION_URL}backoffice/initialDamages/${quotationId}`;
	return axios.get(url, { headers: authHeader() });
};

const addInitialDamage = (quotationId, initialDamageContent) => {
	const data = {
		initialDamage: initialDamageContent,
	};
	let url = `${QUOTATION_URL}revamp/quotation/${quotationId}/initialDamage`;
	return axios.post(url, data, { headers: authHeader() });
};

const updateInitialDamage = (quotationId, initialDamageId, initialDamageContent) => {
	const data = {
		id: initialDamageId,
		initialDamage: initialDamageContent,
	};
	let url = `${QUOTATION_URL}revamp/quotation/${quotationId}/initialDamage`;
	return axios.put(url, data, { headers: authHeader() });
};

const deleteInitialDamage = (quotationId, initialDamageId) => {
	let url = `${QUOTATION_URL}revamp/quotation/${quotationId}/initialDamage/${initialDamageId}`;
	return axios.delete(url, { headers: authHeader() });
};

const getDamageAssessmentResultByQuotationId = (quotationId) => {
	let url = `${QUOTATION_URL}damage-assessor/result-data/${quotationId}`;
	return axios.get(url, { headers: authHeader() });
};

const getNewQuotationList = (page = 1, limit = 10, quotationNo = '', customerName = '', quotationId = '') => {
	let url = `${QUOTATION_URL}backoffice/quotation-list?page=${page}&limit=${limit}`;
	if (quotationNo) {
		url += `&quotationNo=${quotationNo}`;
	}
	if (customerName) {
		url += `&customerName=${customerName}`;
	}
	if (quotationId) {
		url += `&quotationId=${quotationId}`;
	}
	return axios.get(url, { headers: authHeader() });
};

const getQuotationDetail = (quotationId) => {
	let url = `${QUOTATION_URL}backoffice/quotation-detail/${quotationId}`;
	return axios.get(url, { headers: authHeader() });
};
const sendVehicleData = (vehicleData) => {
	let url = `${QUOTATION_URL}test/import-vehicles-list`;
	return axios.post(url, vehicleData, { headers: authHeader() });
}

export default {
	getQuotationList,
	approveQuotation,
	declineQuotation,
	deleteQuotation,
	getInitialDamagesByQuotationId,
	addInitialDamage,
	updateInitialDamage,
	deleteInitialDamage,
	getWaitingApprovalQuotationList,
	getDamageAssessmentResultByQuotationId,
	getNewQuotationList,
	getQuotationDetail,
	sendVehicleData
};
