import React, { useState, useEffect } from 'react';
import {
	IconButton,
	TableCell,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Paper,
	Tooltip,
	Button,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import CheckIcon from '@mui/icons-material/Check';
import BlockIcon from '@mui/icons-material/Block';
import { toLocaleString, paymentStatusFormat } from '../../../util';
import Loader from '../../../components/Loader';
import authService from '../../../services/auth.service';

const PaymentTable = ({
	data,
	handleApprove,
	handleDecline,
	handleView,
	setPaymentDataPage,
	setPaymentDataLimit,
	paymentDataPage,
	paymentDataLimit,
}) => {
	const [gridLoader, setGridLoader] = useState(false);
	const [openDeclineDialog, setOpenDeclineDialog] = useState(false);
	const [selectedRow, setSelectedRow] = useState({
		quotationId: null,
		userId: null,
		declineReason: '',
	});
	const [canEdit, setCanEdit] = useState(true);

	useEffect(() => {
		setGridLoader(false);
		const user = authService.getCurrentUser();
		if (user?.permissions && user?.permissions === 'read-only') {
			setCanEdit(false);
		}
	}, [data?.paymentDetails]);

	const handleChangePage = (event, newPage) => {
		setGridLoader(true);
		setPaymentDataPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setGridLoader(true);
		setPaymentDataPage(0);
		setPaymentDataLimit(+event.target.value);
	};

	const handleValueChanged = (event) => {
		setSelectedRow((current) => {
			return {
				...current,
				[event.target.name]: event.target.value,
			};
		});
	};

	const handleDeclineButton = (quotationId, userId) => {
		setSelectedRow((current) => {
			return {
				...current,
				quotationId,
				userId,
			};
		});
		setOpenDeclineDialog(true);
	};

	return (
		<>
			<Paper sx={{ position: 'relative', overflowX: 'clip' }}>
				{data?.lengthDoc === 0 && <Loader message={data} />}
				{data?.lengthDoc && (
					<>
						{(!data?.paymentDetails || gridLoader) && <Loader showLoader />}
						<TableContainer>
							<Table stickyHeader aria-label="sticky table">
								<TableHead>
									<TableRow>
										<TableCell align="left">Customer Name</TableCell>
										<TableCell align="left">Company Name</TableCell>
										<TableCell align="left">Email</TableCell>
										<TableCell align="left">Mobile Number</TableCell>
										<TableCell align="left">Payment Method</TableCell>
										<TableCell align="left">Bank Name</TableCell>
										<TableCell align="left">Payment Status</TableCell>
										<TableCell align="right">Payment Amount</TableCell>
										{canEdit && <TableCell align="center">Action</TableCell>}
									</TableRow>
								</TableHead>
								<TableBody>
									{data?.paymentDetails?.map((row) => {
										return (
											<TableRow hover role="checkbox" key={row?._id}>
												<TableCell align="left">{row?.customerName}</TableCell>
												<TableCell align="left">{row?.insuranceProduct?.company?.companyName}</TableCell>
												<TableCell align="left">{row?.email}</TableCell>
												<TableCell align="left">{row?.mobileNumber}</TableCell>
												<TableCell align="left">{row?.payment?.payment_method}</TableCell>
												<TableCell align="left">{row?.payment?.sender_bank}</TableCell>
												<TableCell align="left">{paymentStatusFormat(row?.paymentStatus)}</TableCell>
												<TableCell align="right">{toLocaleString(row?.paymentAmount)}</TableCell>
												{canEdit && (
													<TableCell align="center">
														<Tooltip title="View">
															<IconButton size="small" onClick={(event) => handleView(event, row)}>
																<ArticleOutlinedIcon fontSize="small" />
															</IconButton>
														</Tooltip>
														{row?.paymentStatus === 'WAITING_APPROVAL' && (
															<>
																<Tooltip title="Approve">
																	<IconButton
																		color="success"
																		size="small"
																		onClick={(event) => handleApprove(row?._id, row?.userId)}
																	>
																		<CheckIcon fontSize="small" />
																	</IconButton>
																</Tooltip>
																<Tooltip title="Decline">
																	<IconButton
																		color="warning"
																		size="small"
																		onClick={(event) => handleDeclineButton(row?._id, row?.userId)}
																	>
																		<BlockIcon fontSize="small" />
																	</IconButton>
																</Tooltip>
															</>
														)}
													</TableCell>
												)}
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</TableContainer>
						<TablePagination
							rowsPerPageOptions={[10, 25, 50, 100]}
							component="div"
							count={data.lengthDoc}
							rowsPerPage={paymentDataLimit}
							page={paymentDataPage}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</>
				)}
			</Paper>

			<Dialog
				open={openDeclineDialog}
				onClose={() => {
					setOpenDeclineDialog(false);
				}}
			>
				{/* <DialogTitle>Decline Reason</DialogTitle> */}
				<DialogContent>
					{/* <DialogContentText>Please write delcine reason.</DialogContentText> */}
					<TextField
						autoFocus
						margin="dense"
						id="declineReason"
						label="Reason"
						name="declineReason"
						type="text"
						onChange={handleValueChanged}
						fullWidth
						variant="standard"
					/>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => {
							setOpenDeclineDialog(false);
						}}
					>
						Cancel
					</Button>
					<Button
						onClick={() => {
							handleDecline(selectedRow.quotationId, selectedRow.userId, selectedRow.declineReason);
							setOpenDeclineDialog(false);
						}}
					>
						Submit
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default PaymentTable;
