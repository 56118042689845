import React from 'react'
import PostLogin from '../../layout/post-login'
import { Typography } from '@mui/material'
const Settings = () => {
  return (
    <PostLogin>
      <Typography variant='h5'>
        Settings page
      </Typography>
    </PostLogin>
  )
}

export default Settings
