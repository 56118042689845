import React from 'react'
import { FormLabel, TextFieldWrapper, TextFieldStyled } from './style'

const MuiTextField = (props) => {
  return (
    <>
      {props.title && <FormLabel>{props.title}</FormLabel>}
      {props.name &&
        <TextFieldWrapper>
          <TextFieldStyled
            {...props}
          />
        </TextFieldWrapper>}
    </>
  )
}

export default MuiTextField
