import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Box, Button, Input, TextField, Typography } from '@mui/material';
import './style.css';
import QuotationTable from './components/quotationTable';
import PostLogin from '../../layout/post-login';
import Loader from '../../components/Loader';
import QuotationService from '../../services/quotation.service';
import QuotationView from './components/quotationView';
import ExportQuotation from './components/exportQuotations';
const QuotationComponent = (props) => {
	const { enqueueSnackbar } = useSnackbar();
	const [quotationData, setQuotationData] = useState([]);
	const [allQuotations, setAllQuotations] = useState(0);
	const [triggerLoad, setTriggerLoad] = useState(false);

	const [quotationDataPage, setQuotationDataPage] = useState(1);
	const [quotationDataLimit, setQuotationDataLimit] = useState(10);
	const [openPaymentView, setOpenPaymentView] = useState(false);
	const [openingView, setOpeningView] = useState(null);
	const [openExportView, setOpenExportView] = useState(false);
	const [openExportOrderView, setOpenExportOrderView] = useState(false);
	const [openExportApproval, setopenExportApproval] = useState(false);
	const [selectedRow, setSelectedRow] = useState({});

	const [quotationNo, setQuotationNo] = useState('');
	const [customerName, setcustomerName] = useState('');
	const [quotationId, setQuotationId] = useState('');

	const loadQuotation = (quotationDataPage, quotationDataLimit, quotationNo, customerName, quotationId) => {
		QuotationService.getNewQuotationList(quotationDataPage, quotationDataLimit, quotationNo, customerName, quotationId)
			.then((response) => {
				setQuotationData(response?.data?.data);
				setAllQuotations(response?.data?.totalQuotations);
			})
			.catch((err) => {
				if (err?.response?.data) {
					enqueueSnackbar(err.response.data.message, { variant: 'error' });
				} else {
					enqueueSnackbar(err.message, { variant: 'error' });
				}
			});
	};
	const handleClose = () => {
		setOpenPaymentView(false);
		setSelectedRow({});
	};

	useEffect(() => {
		console.log('load payment data');
		loadQuotation(quotationDataPage, quotationDataLimit, quotationNo, customerName);
	}, [quotationDataPage, quotationDataLimit, triggerLoad]);

	const handleApprove = (quotationId) => {
		console.log(quotationId);
		QuotationService.approveQuotation(quotationId)
			.then((response) => {
				enqueueSnackbar('Quotation status has been changed.', { variant: 'success' });
				setQuotationData(null);
				setTriggerLoad(!triggerLoad);
				// loadPaymentData();
			})
			.catch((err) => {
				console.log(err);
				if (err?.response?.data) {
					enqueueSnackbar('Approvement process failed.', { variant: 'error' });
				} else {
					enqueueSnackbar('Approvement process failed.', { variant: 'error' });
				}
			});
	};
	const handleDecline = (quotationId, reason) => {
		QuotationService.declineQuotation(quotationId, reason)
			.then((response) => {
				enqueueSnackbar('Quotation status has been changed.', { variant: 'success' });
				setQuotationData(null);
				setTriggerLoad(!triggerLoad);
				// loadPaymentData();
			})
			.catch((err) => {
				console.log(err);
				if (err?.response?.data) {
					enqueueSnackbar('Decline process failed.', { variant: 'error' });
				} else {
					enqueueSnackbar('Decline process failed.', { variant: 'error' });
				}
			});
	};
	const handleView = (event, row) => {
		event.stopPropagation();
		setOpeningView(row.id);
		QuotationService.getQuotationDetail(row.id)
			.then((response) => {
				setSelectedRow(response?.data?.data);
				setOpenPaymentView(true);
				setOpeningView(null);
			})
			.catch((err) => {
				console.log(err);
				if (err?.response?.data) {
					enqueueSnackbar('Failed to get quotation detail.', { variant: 'error' });
				} else {
					enqueueSnackbar('Failed to get quotation detail.', { variant: 'error' });
				}
			});
	};
	const handleCloseExport = () => {
		setOpenExportView(false);
		setSelectedRow({});
	};
	const handleExportView = (event) => {
		event.stopPropagation();
		setOpenExportView(true);
		setSelectedRow({});
	};
	const handleCloseExportOrder = () => {
		setOpenExportOrderView(false);
		setSelectedRow({});
	};
	const handleExportOrderView = (event) => {
		event.stopPropagation();
		setOpenExportOrderView(true);
		setSelectedRow({});
	};
	const handleCloseExportApproval = () => {
		setopenExportApproval(false);
		setSelectedRow({});
	};
	const handleOpenExportApproval = (event) => {
		event.stopPropagation();
		setopenExportApproval(true);
		setSelectedRow({});
	};

	const handleQuotationNoChanged = (event) => {
		setQuotationNo(event.target.value);
	};
	const handlecustomerNameChanged = (event) => {
		setcustomerName(event.target.value);
	};
	const handleQuotationIdChanged = (event) => {
		setQuotationId(event.target.value);
	};
	const handleSearch = () => {
		setQuotationDataPage(1);
		loadQuotation(quotationDataPage, quotationDataLimit, quotationNo, customerName, quotationId);
	};
	const handleClear = () => {
		setQuotationNo('');
		setcustomerName('');
		setQuotationId('');
		setQuotationDataPage(1);
		loadQuotation(quotationDataPage, quotationDataLimit, '', '', '');
	};
	return (
		<PostLogin>
			<Typography variant="h5" gutterBottom component="div">
				<Box component="span" m={1} display="flex" justifyContent="space-between" alignItems="center">
					Quotation Page
					<TextField
						autoFocus
						margin="dense"
						id="quotationId"
						label="Quotation ID"
						name="quotationId"
						type="text"
						onChange={handleQuotationIdChanged}
						variant="standard"
						defaultValue={quotationId}
						value={quotationId}
					/>
					<TextField
						autoFocus
						margin="dense"
						id="quotationNo"
						label="Quotation No"
						name="quotationNo"
						type="text"
						onChange={handleQuotationNoChanged}
						variant="standard"
						defaultValue={quotationNo}
						value={quotationNo}
					/>
					<TextField
						autoFocus
						margin="dense"
						id="customerName"
						label="Customer Name"
						name="customerName"
						type="text"
						onChange={handlecustomerNameChanged}
						variant="standard"
						defaultValue={customerName}
						value={customerName}
					/>
					<Button id="search" variant="outlined" sx={{ height: 40 }} onClick={handleSearch}>
						Search
					</Button>
					<Button variant="outlined" sx={{ height: 40 }} onClick={handleClear}>
						Clear
					</Button>
					<Button variant="outlined" sx={{ height: 40 }} onClick={(event) => handleExportView(event)}>
						Export
					</Button>
				</Box>
				<Box component="span" m={1} display="flex" justifyContent="space-between" alignItems="center">
					<Box sx={{ marginRight: 'auto' }} />
					<Button
						variant="outlined"
						sx={{ height: 40, marginLeft: 5 }}
						onClick={(event) => handleExportOrderView(event)}
					>
						Export Order
					</Button>
					<Button
						variant="outlined"
						sx={{ height: 40, marginLeft: 5 }}
						onClick={(event) => handleOpenExportApproval(event)}
					>
						Export Approved Order
					</Button>
				</Box>
			</Typography>
			<br />

			<br />
			{!quotationData && <Loader showLoader outlinedLoader />}
			<QuotationTable
				data={quotationData}
				handleApprove={handleApprove}
				handleDecline={handleDecline}
				handleView={handleView}
				openingView={openingView}
				setQuotationDataPage={setQuotationDataPage}
				setQuotationDataLimit={setQuotationDataLimit}
				quotationDataPage={quotationDataPage}
				quotationDataLimit={quotationDataLimit}
				allQuotations={allQuotations}
			/>
			{openPaymentView && (
				<QuotationView
					data={selectedRow}
					onClose={handleClose}
					refetch={loadQuotation}
					open={openPaymentView}
					{...props}
				/>
			)}
			{openExportView && (
				<ExportQuotation onClose={handleCloseExport} refetch={loadQuotation} open={openExportView} {...props} />
			)}
			{openExportOrderView && (
				<ExportQuotation
					onClose={handleCloseExportOrder}
					refetch={loadQuotation}
					open={openExportOrderView}
					type="order"
					{...props}
				/>
			)}
			{openExportApproval && (
				<ExportQuotation
					onClose={handleCloseExportApproval}
					refetch={loadQuotation}
					open={openExportApproval}
					type="approval"
					{...props}
				/>
			)}
		</PostLogin>
	);
};
export default QuotationComponent;
