import React, { useState, useRef } from 'react';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import AuthService from '../../services/auth.service';
import { Link } from 'react-router-dom';
import { Box, TextField, Typography } from '@mui/material';
import PreLogin from '../../layout/pre-login';
import { LoginButton, StyledSecondaryLink } from './style';

const Login = (props) => {
	const form = useRef();

	const [loader, setLoader] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	const onSubmit = (data) => {
		setLoader(true);
		AuthService.loginApi(data)
			.then((resp) => {
				setLoader(false);
				props.history.push('/agents');
				enqueueSnackbar('Logged in Successfully', {
					variant: 'success',
				});
			})
			.catch((error) => {
				setLoader(false);
				const resMessage =
					(error.response && error.response.data && error.response.data.message) || error.message || error.toString();

				enqueueSnackbar(resMessage, { variant: 'error' });
			});
	};

	const validation = (values) => {
		const errors = {};
		if (!values.password) {
			errors.password = 'Password is required';
		}
		return errors;
	};

	const isLocal = window.location.host.indexOf('localhost') !== -1;

	const formik = useFormik({
		validateOnChange: false,
		validateOnBlur: false,
		initialValues: {
			email: isLocal ? 'lan@gmail.com' : '',
			password: isLocal ? 'test123' : '',
		},
		validationSchema: Yup.object({
			email: Yup.string().required('Email is required'),
			password: Yup.string().required('Password is required'),
		}),
		validate: (values) => validation(values),
		onSubmit: (values) => {
			const updatedValues = { ...values };
			onSubmit(updatedValues);
		},
	});

	return (
		<PreLogin>
			<form onSubmit={formik.handleSubmit} ref={form}>
				<Box>
					<Typography color="textPrimary" variant="h4">
						Sign in
					</Typography>
					<Typography color="textSecondary" gutterBottom variant="body2">
						Sign in on the internal platform
					</Typography>
				</Box>

				<TextField
					error={Boolean(formik.touched.email && formik.errors.email)}
					fullWidth
					helperText={formik.touched.email && formik.errors.email}
					margin="normal"
					name="email"
					label="Email Address"
					onBlur={formik.handleBlur}
					onChange={formik.handleChange}
					type="text"
					value={formik.values.email}
					variant="outlined"
				/>
				<TextField
					error={Boolean(formik.touched.password && formik.errors.password)}
					fullWidth
					helperText={formik.touched.password && formik.errors.password}
					margin="normal"
					name="password"
					label="Password"
					onBlur={formik.handleBlur}
					onChange={formik.handleChange}
					type="password"
					value={formik.values.password}
					variant="outlined"
				/>
				<LoginButton
					size="large"
					fullWidth
					variant="contained"
					type="submit"
					onClick={formik.handleSubmit}
					disabled={loader}
				>
					Sign In Now
				</LoginButton>
			</form>
		</PreLogin>
	);
};

export default Login;
